import { IonButton, IonCheckbox, IonCol, IonItem, IonProgressBar, IonRow, IonToast } from "@ionic/react";
import { optionsOutline, statsChartOutline } from "ionicons/icons";
import { FC, useReducer, useState } from "react";
import { EnergyChart } from "../../components/charts/EnergyChart";
import { energyUnits } from "../../components/charts/EnergyLegend";
import { TableCollapser } from "../../components/CollapsableTable";
import { DvcPage } from "../../components/navigation/PageList";
import { RouterMap } from "../../components/navigation/RouterMap";
import { OverviewCard } from "../../components/OverviewCard";
import { Page } from "../../components/Page";
import { TimeRangeInput } from "../../components/TimeRangeInput";
import { SaveAsCSV, SaveAsXLSX } from "../../functions/ExportEnergies";
import { useLoc } from "../../functions/Language";
import { Resolution, useEnergyLoader } from "../../functions/networking/EnergyLoading";
import { useEnergyData } from "../../functions/useEnergyData";
import { tuple } from "../../FunnyTypes";
import { OptionsButton } from "../../components/OptionsButton";

const route = RouterMap.energy;

const Content : FC = () => {
  const loc = useLoc({ en, cs });
  
  const startState = useState(StartDate);
  const endState = useState(new Date);
  const [rangeGen, advanceRangeGen] = useReducer((v : number) => v + 1, 0);

  const [err, setErr] = useState<string | null>(null);

  const [desiredStart, setDesiredStart] = useState<Date>(startState[0]);
  const [desiredEnd, setDesiredEnd] = useState<Date>(endState[0]);
  const [desiredRes, setDesiredRes] = useState(Resolution.month);

  const [power, setPower] = useState(false);

  const emOpen = useState(false);

  const loader = useEnergyLoader(desiredStart, desiredEnd, desiredRes);

  const data = useEnergyData(loader.data.current, loader.suggestedRes, loc.quantNames, power, loader.minorDataVersion);

  const Options = <OptionsButton icon={optionsOutline} title={loc.options}>
    {Close => <>
      <TimeRangeInput load={(start, end, res) => {
        Close();
        setDesiredStart(start);
        setDesiredEnd(end);
        setDesiredRes(res);
        advanceRangeGen();
      }} />

      <IonItem>
        <IonCheckbox checked={power} onIonChange={ev => setPower(ev.detail.checked)}>{loc.power}</IonCheckbox>
      </IonItem>
      <IonItem>
        <IonButton
          disabled={loader.progress < 1}
          onClick={async function ()
          {
            try {
              await SaveAsXLSX(data, loc.xlsxName);
            } catch (e) {
              console.error(e);
              setErr(loc.exportError);
            }
          }}
          style={{ height: "3em", "--border-radius": "6px" }}
        >{loc.exportXlsx}</IonButton>
        <IonButton
          disabled={loader.progress < 1}
          onClick={async function ()
          {
            try {
              await SaveAsCSV(data, loc.csvName)
            } catch (e) {
              console.error(e);
              setErr(loc.exportError);
            }
          }}
          style={{ height: "3em", "--border-radius": "6px" }}
        >{loc.exportCsv}</IonButton>
      </IonItem>

      <IonToast
        isOpen={err !== null}
        duration={5000}
        onWillDismiss={() => setErr(null)}
        message={err ?? ""}
        position="top"
        buttons={[{
          text: "OK",
          role: "cancel"
        }]}
      />
    </>}
  </OptionsButton>

  return <Page title={loc.title} loaders={loader.loaders} additionalButtons={Options}>
    <EnergyChart
      hidden={loader.progress < 1}
      data={loader.data.current}
      resolution={loader.suggestedRes}
      updateRes={(r, s, e) => {
        setDesiredRes(r);
        setDesiredStart(s);
        setDesiredEnd(e);
      }}
      start={desiredStart}
      end={desiredEnd}
      majorDataVersion={loader.majorDataVersion}
      rangeGen={rangeGen}
      power={power}
      edata={data}
    />
    <IonItem style={{ display: loader.progress >= 1 ? "none" : "block" }}>
      <IonProgressBar type="determinate" value={loader.progress} />
    </IonItem>
    <div style={{width: "100%"}} className="lastchild">
      <TableCollapser headerFields={[""]} title={loc.counters} sizes={["6", "6"]} openState={emOpen}>
        <div className="bordered">
          <Row label={loc.quantNames[0]} value={loader.onlnieData?.Data.Energy_T1.Consumption.Active_kWh} unit={energyUnits[0]} />
          <Row label={loc.quantNames[1]} value={loader.onlnieData?.Data.Energy_T1.Consumption.Inductive_kvarh} unit={energyUnits[1]} />
          <Row label={loc.quantNames[2]} value={loader.onlnieData?.Data.Energy_T1.Consumption.Capacity_kvarh} unit={energyUnits[2]} />
        </div>
        <div className="bordered">
          <Row label={loc.quantNames[3]} value={loader.onlnieData?.Data.Energy_T1.Distribution.Active_kWh} unit={energyUnits[0]} />
          {/* ind a cap dodavka jsou prohozene -- promenne tady jsou pojmenovane postaru */}
          <Row label={loc.quantNames[4]} value={loader.onlnieData?.Data.Energy_T1.Distribution.Inductive_kvarh} unit={energyUnits[1]} />
          <Row label={loc.quantNames[5]} value={loader.onlnieData?.Data.Energy_T1.Distribution.Capacity_kvarh} unit={energyUnits[2]} />
        </div>
      </TableCollapser>
    </div>
  </Page>
}

const Row : FC<{
  label : string,
  value : number | undefined,
  unit : string,
}> = (props) => {
  return <IonRow>
    <IonCol size="6">{props.label} [{props.unit}]</IonCol>
    <IonCol size="6" style={{textAlign: "right"}}>{formatLargeInt(props.value ?? 0)}&nbsp;</IonCol>
  </IonRow>
}

function formatLargeInt(v : number) {
  v = Math.abs(v / 10);
  let tail = "." + (v % 1).toFixed(1)[2];
  let mid = (v % 1e3).toFixed(0);
  v = Math.floor(v / 1000);

  while (v) {
    tail = ' ' + mid.padStart(3, '0') + tail;
    mid = (v % 1e3).toFixed(0);
    v = Math.floor(v / 1000);
  }
  return mid + tail;
}

function StartDate() {
  let date = new Date();
  date.setMonth(date.getMonth() - 6, 1);
  date.setHours(0, 0, 0, 0);
  return date;
}

const Overview : FC = () => {
  const loc = useLoc({en, cs});
  const [start,] = useState<Date>(StartDate); // fix nekonečné smyčky
  const [end,] = useState(new Date)
  const loader = useEnergyLoader(start, end, Resolution.month);
  const data = useEnergyData(loader.data.current, Resolution.month, loc.quantNames, false, loader.minorDataVersion);

  return <OverviewCard href={route} title={loc.title}>
    <IonProgressBar type="determinate" value={loader.progress} style={{ visibility: loader.progress >= 1 ? "hidden" : "visible" }} />
    <EnergyChart
      data={loader.data.current}
      resolution={Resolution.month}
      simple
      hidden={loader.progress < 1}
      edata={data}
    />
  </OverviewCard>
}


const en = {
  title : "Energy",

  power: "Show average power",

  quantNames: [
    "Active consumption", "Reactive L consumption", "Reactive C consumption",
    "Active distribution", "Reactive C distribution", "Reactive L distribution"
  ] as tuple<string, 6>,

  counters: "Energy counters",

  exportCsv: "Export to .csv",
  csvName: "energies.csv",
  exportXlsx: "Export to .xlsx",
  xlsxName: "energies.xlsx",
  exportError: "Exporting failed.",

  options: "Options",
}

const cs : typeof en = {
  title: "Energie",

  power: "Zobrazovat průměrný výkon",

  quantNames: [
    "Činný odběr", "Jalový L odběr", "Jalový C odběr",
    "Činná dodávka", "Jalová C dodávka", "Jalová L dodávka"
  ],

  counters: "Hodnoty elektroměru",

  exportCsv: "Exportovat do .csv",
  csvName: "energie.csv",
  exportXlsx: "Exportovat do .xlsx",
  xlsxName: "energie.xlsx",
  exportError: "Exportování selhalo.",

  options: "Možnosti",
}

export const Energy : DvcPage & { overview : typeof Overview } = {
  page : Content,
  path : route,
  title : { en, cs },
  icon : statsChartOutline,
  overview : Overview,

  available(dvc) {
    return dvc.Equipment.Data_flash;
  }
}