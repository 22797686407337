import { Preferences } from "@capacitor/preferences";
import { useEffect, useRef, useState } from "react";

export function usePersistentState<T>(key : string, parser : (val : string | null) => T) {
  const valState = useState(parser(null));
  const [val, setVal] = valState;
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    Preferences.get({ key }).then(({ value }) => {
      if (mounted.current)
        setVal(parser(value));
    });

    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    Preferences.set({ key, value : `${val}` });
  }, [val]);

  return valState;
}