import { IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal } from "@ionic/react";
import { Dispatch, FC, useId } from "react";
import { zfill } from "../functions/Formatting";

export const DateInputItem : FC<{
  label : string,
  value : Date,
  setValue : Dispatch<Date>,
  presentation : "date" | "month-year",
  locale : string
}> = (props) => {
  const id = "DateInput_" + useId();

  return <IonItem>
    <IonLabel>{props.label}</IonLabel>
    <IonDatetimeButton datetime={id} key={props.presentation + props.locale} />

    <IonModal keepContentsMounted>
      <IonDatetime
        firstDayOfWeek={1}
        locale={props.locale}
        id={id}
        presentation={props.presentation}
        value={ToDateString(props.value)}
        onIonChange={
          ev => {
            let date = new Date(ev.detail.value as string);
            date.setHours(0, 0, 0, 0);
            if (!isNaN(date.valueOf()))
              props.setValue(date);
          }
        }
      />
    </IonModal>
  </IonItem>
}

function ToDateString(date : Date) {
  return `${date.getFullYear()}-${zfill(date.getMonth() + 1)}-${zfill(date.getDate())}`;
}