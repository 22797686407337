import { Fetch, ToDate } from "./Networking";
import { tuple } from "../../FunnyTypes";
import { useEffect, useReducer, useRef, useState } from "react";
import { RType, zero_6tupple } from "./NetTypes";
import { useLoader } from "./useLoader";

const MAX_SECTOR_SIZE = 55;
const SECTOR_SIZE = 50; console.assert(SECTOR_SIZE <= MAX_SECTOR_SIZE);
const STEP_WITHIN_DAY = 48; console.assert(STEP_WITHIN_DAY <= MAX_SECTOR_SIZE);

/**
 * Rozlišení jsou zaručeně seřazená tak, aby nejjemnější mělo nejnižší číslo
 */
export enum Resolution {
  qh = 0,
  hour = 1,
  day = 2,
  month = 3
}

/**
 * Předpokládá se, že rozdíl po sobě jdoucích hodnot se vejde do "normálního" čísla, tj. je menší než něco jako 2^50
 */
function vsub<n extends number>(a: tuple<bigint, n>, b: tuple<bigint, n>) {
  return a.map((v, i) => Math.abs(Number(BigInt.asIntN(64, v - b[i])) / 10)) as tuple<number, n>;
}

function GetDayNumber(date: Date) {
  let copy = new Date(date.valueOf());
  copy.setHours(12, 0, 0, 0);
  return (copy.valueOf() / (12 * 36e5)) >> 1;
}

function DateFromDayNumber(n: number) {
  let res = new Date(0);
  res.setDate(n)
  res.setHours(0);
  return res;
}

export type EnergyData = {
  time: Date,
  values: tuple<number, 6>
}[];

type Counter = tuple<bigint, 6>

type DayValue = {
  firstValue: Counter,
  lastValue: Counter,
  firstCertain: boolean,
  lastCertain: boolean,
  /** První čítač se shoduje s firstValue, poslední s lastValue. V běžný den je zde uloženo 97 hodnot. */
  details: (Counter | undefined)[],
  detailFile: number,
  selfFile: number | null,
  // day: Date,
}
const DETAIL_NOT_LOADED_TRESHOLD = 20;

function InsertSorted(arr : number[], elem : number) {
  let i = arr.findIndex(v => v >= elem);
  if (i < 0) arr.push(elem);
  else if (arr[i] !== elem) arr.splice(i, 0, elem);
  return i < 0 ? arr.length - 1 : i;
}

class Cache {
  values: { [k: number]: DayValue | undefined } = {};
  storedDays : number[] = [];
  storedDayFiles : number[] = [];
  minDayFile = 1;
  maxDayFile = 0;
  minQHFile = 1;
  maxQHFile = 0;
  rangesAreValid = false;

  public SetFileRanges(minDay: number, maxDay: number, minQH: number, maxQH: number) {
    this.minDayFile = minDay;
    this.maxDayFile = maxDay;
    this.minQHFile = minQH;
    this.maxQHFile = maxQH;
    this.rangesAreValid = minDay <= maxDay && minQH <= maxQH && minDay > 0 && minQH > 0;
  }

  private StoreDay(day: number, startingCounter: Counter, detailFile: number, selfFile: number | null, certain: boolean) {
    let i = InsertSorted(this.storedDays, day);
    this.SetLastValue(this.storedDays[i - 1], startingCounter, certain);

    let lastValue = startingCounter;
    let nextDay = this.values[this.storedDays[i + 1]];
    if (nextDay) {
      lastValue = nextDay.firstValue;
    }

    this.values[day] = {
      // day: DateFromDayNumber(day),
      details: new Array(97).fill(null),
      detailFile,
      selfFile,
      lastCertain: false,
      lastValue: lastValue,
      firstCertain: certain,
      firstValue: startingCounter,
    }

    return this.values[day]!;
  }

  private SetLastValue(day: number, value: Counter, certain: boolean) {
    const dayta = this.values[day];
    if (!dayta) return;
    dayta.lastValue = value;
    dayta.details[dayta.details.length - 1] = value;
    dayta.lastCertain = certain;
  }

  private StoreQH(day: number, qh: number, counter: Counter, file: number) {
    if (qh === 0) {
      let yesterday = this.values[day - 1] ?? this.StoreDay(day - 1, counter, file - 97, null, false);
      yesterday.details[yesterday.details.length - 1] = counter;
    }
    let today = this.values[day] ?? this.StoreDay(day, counter, file - qh, null, false);
    //if (qh < 96)
    today.details[qh] = counter;
  }

  /**
   * Generuje aktuální progress
   */
  public async * LoadDays(from : Date, to : Date, force : boolean) {
    if (!this.rangesAreValid) {
      yield 1;
      return;
    }

    let start = GetDayNumber(from);
    let end = GetDayNumber(to);
    let largestPossibleEndFile = this.values[end]?.selfFile ?? -1;
    let smallestPossibleStartFile = this.values[start]?.selfFile ?? this.minDayFile;

    if (largestPossibleEndFile === -1) {
      let firstLargerDay = this.storedDays.find(d => d > end && this.values[d]!.selfFile);
      if (!firstLargerDay) largestPossibleEndFile = this.maxDayFile;
      else {
        largestPossibleEndFile = this.values[firstLargerDay]!.selfFile! - 1;
        smallestPossibleStartFile = largestPossibleEndFile + start - firstLargerDay;
      }
    }
    if (smallestPossibleStartFile < this.minDayFile) smallestPossibleStartFile = this.minDayFile;

    for (let endFile = largestPossibleEndFile; endFile >= smallestPossibleStartFile; endFile -= SECTOR_SIZE) {
      yield (largestPossibleEndFile - endFile) / (largestPossibleEndFile - smallestPossibleStartFile + 1);
      const File_Count = Math.min(endFile - this.minDayFile + 1, SECTOR_SIZE);
      if (!force) {
        let new_data = false;
        for (let i = 0; i < File_Count; i++) {
          // todo: fix O(n^2)
          if (!this.storedDayFiles.includes(endFile - File_Count + 1 + i)) {
            new_data = true;
            break;
          }
        }
        if (!new_data) continue;
      }

      let response = await Fetch("Energy_Per_Day_File", {
        File_ID: endFile - File_Count + 1,
        File_Count,
        Tariff_Count: 1
      });

      if (response.err) {
        console.warn(`Failed to load sector ${endFile - File_Count + 1} -- ${endFile}`, response.kind);
        continue; // retry once ?
      }

      const fileContents = response.val.Data;
      const files: number[] = Object.keys(fileContents).map(v => Number.parseInt(v)).sort((a, b) => a - b);

      for (let file of files) {
        const day = fileContents[file];
        const time = ToDate(day.Date_time);
        const dayId = GetDayNumber(time);
        if (dayId === start) {
          // ukonci vnejsi smycku
          smallestPossibleStartFile = +Infinity;
        }
        // TODO: special status na reset flashky
        if (day.Status === "Error" && day.Date_time === 0) {
          this.StoreDay(0, day.T1, this.minQHFile, file, false);
          for await (let progress of this.LoadDayDetail(0));
          InsertSorted(this.storedDayFiles, file);
          continue;
        }

        this.StoreDay(dayId, day.T1, day.ID_detail ?? 0, file, true);
        InsertSorted(this.storedDayFiles, file);

        if (file === this.minDayFile) {
          this.StoreDay(dayId - 1, day.T1, (day.ID_detail ?? 96) - 96, null, false);
          for await (let progress of this.LoadDayDetail(dayId - 1));
        }

        if (day.Cleared) {
          this.minDayFile = file;
        }
      }

      if (files.length && smallestPossibleStartFile < this.maxDayFile) {
        smallestPossibleStartFile = Math.max(
          files[0] + start - GetDayNumber(ToDate(fileContents[files[0]].Date_time)),
          this.minDayFile
        );
      }
    }

    yield 1;
  }

  async * LoadDayDetail(day : number) {
    yield 0;
    
    let firstDetailFile = this.values[day]?.detailFile ?? 0;
    if (firstDetailFile === 0 || !this.rangesAreValid) {
      yield 1;
      return;
    }

    let start = DateFromDayNumber(day + 1);
    let end = new Date(start.valueOf());
    end.setDate(end.getDate() + 1);
    let diff = (end.valueOf() - start.valueOf()) / 9e5 + 1; // =4*24+1 pro naprostou většinu dní

    const firstIrrelevantFile = Math.min(firstDetailFile + diff, this.maxQHFile + 1);
    if (firstDetailFile < this.minQHFile) firstDetailFile = this.minQHFile;

    for (
      let File_ID = firstDetailFile;
      File_ID < firstIrrelevantFile;
      File_ID += SECTOR_SIZE
    ) {
      const File_Count = Math.min(SECTOR_SIZE, firstIrrelevantFile - File_ID);
      const response = await Fetch("Energy_File", { File_ID, File_Count, Tariff_Count: 1 });
      yield (File_ID - firstDetailFile + 1) / (firstIrrelevantFile - firstDetailFile + 1);
      
      if (response.err) {
        console.warn(`Failed to load detail sector ${File_ID} -- ${File_ID + File_Count + 1}`, response.kind);
        continue; // retry once ?
      }

      const fileContents = response.val.Data;
      const files = Object.keys(fileContents).map(v => Number.parseInt(v)).sort((a, b) => a - b);

      for (let file of files) {
        const qh = fileContents[file];
        const time = ToDate(qh.Date_time);
        if (qh.Status === "Error") {
          console.warn("Failed to load day", time);
          continue;
        }
        if (qh.Cleared) {
          if (file > this.minQHFile) this.minQHFile = file + 1;
          continue;
        }

        let day = new Date(time);
        day.setHours(0,0,0,0);
        let qhindex = Math.round((time.valueOf() - day.valueOf()) / 9e5);
        this.StoreQH(GetDayNumber(day), qhindex, qh.T1, file)
      }
    }
    yield 1;

    const dayta = this.values[day];
    if (!dayta) return;

    // zmena delky dne při změně času
    if (diff !== dayta.details.length) {
      const last = dayta.details[dayta.details.length - 1] ?? zero_6tupple();
      while (diff <= dayta.details.length) {
        dayta.details.pop();
      }
      while (diff > dayta.details.length) {
        dayta.details.push(last);
      }
    }

    const firstNonNull = dayta.details.findIndex(v => v);
    let lastNonNull = firstNonNull;
    for (let i = dayta.details.length - 1; i >= 0; i--) {
      if (dayta.details[i]) {
        lastNonNull = i;
        break;
      }
    }

    if (firstNonNull >= 0 && !dayta.firstCertain) {
      dayta.firstValue = dayta.details[firstNonNull]!;
      if (firstNonNull === 0) dayta.firstCertain = true;
    }

    if (lastNonNull >= 0 && !dayta.lastCertain) {
      dayta.lastValue = dayta.details[lastNonNull]!;
      if (lastNonNull === dayta.details.length - 1) dayta.lastCertain = true;
    }
  }

  currentQH = -1;
  public async UpdateToday(online: RType<"Online">) {
    const time = new Date(online.Head.Date_time);
    const qh = 4 * time.getHours() + Math.floor(time.getMinutes() / 15) + 1;
    const day = GetDayNumber(time);

    if (this.currentQH !== qh || !this.values[day]) {
      for await (let progress of this.LoadDayDetail(day)) {}

      if (this.values[day]?.details[qh - 1]) {
        this.currentQH = qh;
      }
    }

    const dayta = this.values[day];
    if (dayta) {
      dayta.lastValue = dayta.details[qh] = [
        BigInt.asUintN(64, BigInt(online.Data.Energy_T1.Consumption.Active_kWh)),
        BigInt.asUintN(64, BigInt(online.Data.Energy_T1.Consumption.Inductive_kvarh)),
        BigInt.asUintN(64, BigInt(online.Data.Energy_T1.Consumption.Capacity_kvarh)),
        BigInt.asUintN(64, BigInt(online.Data.Energy_T1.Distribution.Active_kWh)),
        BigInt.asUintN(64, BigInt(online.Data.Energy_T1.Distribution.Inductive_kvarh)),
        BigInt.asUintN(64, BigInt(online.Data.Energy_T1.Distribution.Capacity_kvarh)),
      ];
    }
  }

  /**
   * Generuje pregress -- číslo od 0 (na začátku) do 1 (na konci)
   */
  public async * ReadInterval(start : Date, end : Date, res : Resolution)
  : AsyncGenerator<{ progress : number, result : EnergyData }>
  {
    if (res >= Resolution.day) {
      start = new Date(start.valueOf());
      start.setHours(0, 0, 0, 0);
      end = new Date(end.valueOf());
      end.setHours(0, 0, 0, 0);
    }
    if (res === Resolution.month) {
      start.setDate(1);
      end.setMonth(end.getMonth() + 1, 0);
    }

    const firstDay = GetDayNumber(start);
    const lastDay = GetDayNumber(end);
    
    let result : EnergyData = [];
    for await (let progress of this.LoadDays(start, end, false)) {
      yield { progress: progress * .95, result }
      // Bez tohohle se při načítání velkého množství nezapsaných dní naplánuje tuna překreslení najednou a všechno se zaseká
      await new Promise(res => setTimeout(res, 0));
    }

    switch (res) {
      case Resolution.qh:
      case Resolution.hour: {
        const startOffset = 4 * start.getHours() + Math.floor(start.getMinutes() / 15);
        let endOffset = 4 * end.getHours() + Math.ceil(end.getMinutes() / 15);
        if (endOffset === 0) endOffset = 100;
        
        const step = res === Resolution.hour ? 4 : 1;
        for (let day = firstDay; day <= lastDay; day++) {
          const dayta = this.values[day];
          if (dayta
            && dayta.detailFile !== cache.minQHFile
            && dayta.detailFile !== cache.maxQHFile
            && dayta.details.filter(v => v).length < DETAIL_NOT_LOADED_TRESHOLD
          ) {
            for await (let progress of cache.LoadDayDetail(day)) {
              yield { progress : (day - firstDay + progress) / (lastDay - firstDay + 1), result };
            }
          }
          
          const qhcount = (dayta?.details.length ?? 97) - 1;
          for (let qh = step; qh <= qhcount; qh += step) {
            if ( (day === firstDay && qh <= startOffset)
              || (day === lastDay && qh > endOffset))
              continue;

            let time = new Date(0);
            time.setDate(day + 1);
            time.setHours(0);
            time.setTime(time.valueOf() + (qh - step) * 9e5);

            let values = zeros;
            if (dayta) {
              let a = 0;
              let b = step;
              while (a < b && !dayta.details[qh - step + a]) a++;
              while (b > a && !dayta.details[qh - step + b]) b--;
              if (dayta.details[qh - step + a]) {
                values = vsub(dayta.details[qh - step + b]!, dayta.details[qh - step + a]!);
                // if (values[0] > 10) debugger;
              }
            }

            result.push({ time, values });
          }
          yield { progress : (day - firstDay + 1) / (lastDay - firstDay + 1), result };
        }
        break;
      }
      case Resolution.day: {
        for (let day = firstDay; day <= lastDay; day++) {
          let time = new Date(start.valueOf());
          time.setDate(time.getDate() + day - firstDay);
          let dayta = this.values[day];
          result.push({
            time,
            values: dayta ? vsub(dayta.lastValue, dayta.firstValue) : zeros
          })
        }
        break;
      }
      case Resolution.month:
        for (let from = start; from < end; from.setMonth(from.getMonth() + 1, 1)) {
          let to = new Date(from.valueOf());
          to.setMonth(to.getMonth() + 1, 1);

          let firstDay = GetDayNumber(from);
          let lastDay = GetDayNumber(to);
          while (firstDay < lastDay && !this.values[firstDay]) firstDay++;
          while (lastDay > firstDay && !this.values[lastDay]) lastDay--;
          const firstValue = this.values[firstDay]?.firstValue;
          const lastValue = this.values[lastDay]?.lastValue;

          result.push({
            time: new Date(from.valueOf()),
            values: firstValue && lastValue ? vsub(lastValue, firstValue) : zeros
          });
        }
        break;
    }

    yield { progress: 1, result }
  }
}

const zeros = [0, 0, 0, 0, 0, 0] as tuple<number, 6>;

let cache = new Cache();
export function useEnergyLoader(
  start : Date,
  end : Date,
  resolution : Resolution,
) {
  const rangeLoader = useLoader("Energy_Per_Day_Folder_Info", { Data: { Min_file_id: 1, Max_file_id: 0 } }, false, -1);
  const detailRangeLoader = useLoader("Energy_Folder_Info", { Data: { Min_file_id: 1, Max_file_id: 0 } }, false, -1);
  const onlineLoader = useLoader("Online", null);
  const data = useRef<EnergyData>([]);
  const [minorDataVersion, advanceMinDV] = useReducer((v : number) => v + 1, 0);
  const [majorDataVersion, advanceMajDV] = useReducer((v : number) => v + 1, 0);
  const [progress, setProgress] = useState(0);
  const majorLoading = useRef(false);
  const [rangesLoaded, setRangesLoaded] = useState(false);

  useAsyncEffect(async function LoadToday() {
    if (onlineLoader.data && majorDataVersion > 1) {
      await cache.UpdateToday(onlineLoader.data);
      let last = undefined;
      for await (last of cache.ReadInterval(start, end, resolution));
      if (last) {
        data.current = (last.result);
        advanceMinDV();
      }
    }
  }, [onlineLoader.data, majorDataVersion]);

  useEffect(() => {
    cache.SetFileRanges(
      rangeLoader.data.Data.Min_file_id,
      rangeLoader.data.Data.Max_file_id,
      detailRangeLoader.data.Data.Min_file_id,
      detailRangeLoader.data.Data.Max_file_id,
    );
    setRangesLoaded(cache.rangesAreValid);
  }, [rangeLoader.data.Data, detailRangeLoader.data.Data]);

  useAsyncEffect(async function LoadDesiredRange() {
    if ((!cache.rangesAreValid || end < start) && data.current.length === 0) {
      data.current = [];
      advanceMinDV();
    } else {
      majorLoading.current = true;
      for await (let newData of cache.ReadInterval(start, end, resolution)) {
        setProgress(newData.progress);
        if (newData.progress === 1) {
          data.current = newData.result;
          advanceMinDV();
        }
      }
      majorLoading.current = false;
    }
    
    advanceMajDV();
  }, [start, end, resolution, rangesLoaded]);

  return {
    loaders: [rangeLoader, detailRangeLoader, onlineLoader] as ReturnType<typeof useLoader>[],
    data,
    minorDataVersion,
    suggestedRes: resolution,  // TODO:
    progress,
    majorDataVersion,
    onlnieData: onlineLoader.data
  }
}

function useAsyncEffect(executor : () => void | Promise<void>, dep ?: unknown[]) {
  return useEffect(() => { executor() }, dep);
}

export function ResetEnergyCache() {
  cache = new Cache();
}