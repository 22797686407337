import React, { FC, useEffect, useMemo } from "react";
import { Scatter } from "react-chartjs-2";
import { ChartData, ChartDataset, ChartOptions, Plugin } from "chart.js";
import { FormatNumber } from "../../functions/Formatting";
import { useLoc } from "../../functions/Language";
import { OnlineData } from "../../functions/networking/NetTypes";
import { IonCol, IonItem, IonList, IonRow } from "@ionic/react";

const colors = ["#ff0000", "#00ee00", "#3f3fff"];
function p2c(angles: [number, number, number], magnitudes: [number, number, number], type: "I" | "U") : ChartDataset<"scatter">[] {
  return [0, 1, 2].map(i => ({
    data: [
      { x: 0, y: 0 }, // slouží pouze k nakreslení průvodiče
      {
        x: magnitudes[i%3] * Math.cos(angles[i%3] * Math.PI / 180),
        y: -magnitudes[i%3] * Math.sin(angles[i%3] * Math.PI / 180)
      }
    ],
    xAxisID: `x${type}`,
    yAxisID: `y${type}`,
    borderColor: colors[i],
    backgroundColor: colors[i],
    borderDash: type === "I" ? [10, 6] : undefined,
    borderWidth: 2,
    showLine: true,
    label: `${type}  L${i + 1}`,
    pointHitRadius: [0, 30],
    pointRadius: [0, 3],
    //pointStyle: type === "I" ? "rect" : "circle"
  }));
}

export const PolarChart : FC<{
  data: OnlineData
}> = (props) => {
  const loc = useLoc({en, cs});

  const data_options = useMemo(() => {
    let urange = Math.max(...props.data.U_LN, .1) * 1.02;
    let irange = Math.max(...props.data.Current, .01) * 1.02;
    let axisSteps = GetRadialAxisSteps(urange, irange);
    urange = axisSteps.ustep * axisSteps.steps;
    irange = axisSteps.istep * axisSteps.steps;

    const udata = p2c(props.data.Angle_U, props.data.U_LN, "U");
    const idata = p2c(props.data.Angle_I, props.data.Current, "I");
    
    let res: {data: ChartData<"scatter">, options: ChartOptions<"scatter">, axisSteps : typeof axisSteps} = {
      axisSteps: axisSteps,
      data: {
        datasets: [
          ...udata,
          ...idata
        ],
      },
      options: {
        /*transitions: {
          show: {
            animation: undefined
          },
          hide: {
            animation: undefined,
          }
        },*/
        scales: {
          "xU": {
            min: -urange,
            max: urange,
            display: false
          },
          "yU": {
            min: -urange,
            max: urange,
            display: false
          },
          "xI": {
            min: -irange,
            max: irange,
            display: false
          },
          "yI": {
            min: -irange,
            max: irange,
            display: false
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label(tooltipItem) {
                return " " + tooltipItem.dataset.label;
              },
              afterLabel(tooltipItem) {
                const mag = Math.sqrt((tooltipItem.parsed.x**2) + (tooltipItem.parsed.y**2));
                let angle = Math.atan2(-tooltipItem.parsed.y, tooltipItem.parsed.x) * 180 / Math.PI;
                if (angle < 0) angle += 360;
                const type = tooltipItem.dataset.label?.at(0) === "I" ? "A" : "V"
                return `${type === "A" ? loc.curr : loc.volt}: ${FormatNumber(mag)} ${type}\n${loc.angle}: ${FormatNumber(angle)}°`; // TODO: zjistit uhlovou konvenci
              },
            }
          }
        },
        aspectRatio: 1,
      }
    }

    return res;
  }, [props.data])

  return <>
    <div style={{width: "min(calc(100% - 20px), 80vh)", padding: "10px", position: "relative", marginInline: "auto"}}>
      <div style={{paddingTop: "100%"}}></div>
      <Scatter data={data_options.data} options={data_options.options} plugins={[PolarAxes]} style={{position: "absolute", top: 0}} />
      
      <IonRow>
        <IonRow style={{minWidth: "18em", flex: 1}}>
          {colors.map((c, i) => <LegendItem color={c} label={`U${i + 1}`} key={i} />)}
        </IonRow>
        <IonRow style={{minWidth: "18em", flex: 1}}>
          {colors.map((c, i) => <LegendItem color={c} label={` I${i + 1}`} dashed key={i} />)}
        </IonRow>
      </IonRow>
    </div>
  </>
}

const LegendItem : FC<{
  color : string,
  label : string,
  dashed? : boolean,
}> = (props) => {
  return <IonCol>
    <span style={{
      marginBottom: "calc(1ex - 3px)",
      marginInline: "5px",
      width: "3em",
      borderBottom: `${props.dashed ? "dashed" : "solid"} ${props.color} 2px`,
      display: "inline-block"
    }}/>
    <span>{props.label}</span>
  </IonCol>
}

const en = {
  curr: "Current",
  volt: "Voltage",
  angle: "Angle"
}

const cs : typeof en = {
  curr: "Proud",
  volt: "Napětí",
  angle: "Úhel"
}

const PolarAxes: Plugin<"scatter"> = {
  id: "PolarAxes",
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const { top, left, width, height } = chart.chartArea;
    const zero = { x: left + width / 2, y: top + height / 2 }
    const len = width / 2;

    ctx.lineWidth = 1;
    ctx.strokeStyle = "#bbb"
    ctx.moveTo(zero.x - len, zero.y);
    ctx.lineTo(zero.x + len, zero.y);
    const a = len / 2;                // cos 60° = sin 30°
    const b = Math.sqrt(3) * len / 2; // cos 30° = sin 60°
    ctx.moveTo(zero.x - a, zero.y - b);
    ctx.lineTo(zero.x + a, zero.y + b);
    ctx.moveTo(zero.x + a, zero.y - b);
    ctx.lineTo(zero.x - a, zero.y + b);
    ctx.stroke();
    
    ctx.beginPath();
    for (let i = 1; i <= 5; i++) {
      ctx.ellipse(zero.x, zero.y, i * len / 5, i * len / 5, 0, 0, 2 * Math.PI);
    }
    ctx.stroke();

    let scales = chart.config.options?.scales;
    let urange = 1, irange = 1;
    if (scales !== undefined) {
      let _range = scales["xU"]?.max;
      if (typeof _range === "number")
        urange = _range;
      _range = scales["xI"]?.max;
      if (typeof _range === "number")
        irange = _range;
    }
    urange /= 5;
    irange /= 5;
    ctx.fillStyle = "#bbb";
    ctx.save();
    ctx.textAlign = "center";
    ctx.translate(len, len);
    //ctx.rotate(.3);
    ctx.textBaseline = "top";
    for (let i = 1; i <= 5; i++) {
      ctx.fillText(`${FormatNumber(urange * i)}V`, 8, -len / 5 * i + 10);
    }
    //ctx.rotate(-.6);
    ctx.textBaseline = "alphabetic";
    for (let i = 1; i <= 5; i++) {
      ctx.fillText(`${FormatNumber(irange * i)}A`, 8, len / 5 * i);
    }
    ctx.restore();
  }
}

function GetOrder(n: number) {
  let o = 1;
  if (n > 0) {
    if (n < 1) {
      while (n / o < 1) o /= 10;
    } else {
      while (n / o >= 1) o *= 10;
      o /= 10;
    }
  }
  if (n / o > 5) o *= 5;
  if (n / o > 2) o *= 2;
  return o;
}

function StepSize(m: number) {
  return m < 1.15 ? .25 : m < 1.4 ? .3 : m < 1.75 ? .4 : .5;
}

function GetRadialAxisSteps(urange: number, irange: number) {
  let ou = GetOrder(urange);
  let oi = GetOrder(irange * 1.5);
  let ustep = StepSize(urange / ou) * ou;
  let istep = StepSize(irange * 1.5 / oi) * oi;
  return { ustep, istep, steps: Math.floor(Math.max(urange / ustep, irange / istep)) + 1 };
  // steps by mělo být vždy 5
  // pokud ne, někdo nám to řekne
}