import React, { FC, PropsWithChildren, createContext } from "react";
import { Redirect, Route } from "react-router";
import { RouterMap } from "./RouterMap";
import { DeviceInfo } from "../../functions/networking/NetTypes";
import { DEV } from "../../App";

export const ConditionalRoute : FC<PropsWithChildren<{
  path? : string,
  cond : boolean,
  fallback : string
}>> = (props) => <Route path={props.path} exact>
  {props.cond ? props.children : <Redirect to={props.fallback} />}
</Route>

export const DeviceCtx = createContext<DeviceInfo | null>(null);

export const DvcRoute : FC<PropsWithChildren<{
  path? : string
}>> = (props) => <DeviceCtx.Consumer>{dvc =>
  <ConditionalRoute cond={dvc !== null || DEV} fallback={RouterMap.login} path={props.path}>
    {props.children}
  </ConditionalRoute>}
</DeviceCtx.Consumer>