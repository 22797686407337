import { IonItem, IonLabel, IonRow } from "@ionic/react";
import React, { FC } from "react";
import { Page } from "../../components/Page";
import { useLoc } from "../../functions/Language";
import { useLoader } from "../../functions/networking/useLoader";
import { PolarChart } from "../../components/charts/PolarChart";
import { MeasurementsTable } from "./Measurements";
import { DvcPage } from "../../components/navigation/PageList";
import { RouterMap } from "../../components/navigation/RouterMap";
import vector from "../../img/vectors.svg";

const route = RouterMap.vector;
const Link : FC = () => {
  const loc = useLoc({en, cs});
  return <IonItem routerLink={route}>
    <IonLabel>{loc.title}</IonLabel>
  </IonItem>
}

const Content : FC = () => {
  const loc = useLoc({en, cs});
  const loader = useLoader("Online", null);
  
  const loaders = [loader];

  return <Page title={loc.title} loaders={loaders}>
    {loader.data === null ? "" : 
      <IonRow>
        <PolarChart data={loader.data.Data}/>
        <MeasurementsTable data={loader.data.Data} />
      </IonRow>
    }
  </Page>
}

const en = {
  title: "Vector diagram",
};

const cs : typeof en = {
  title: "Vektorový diagram",
}


export const Vector = {
  Link: Link
}

export const PVector : DvcPage = {
  page: Content,
  path: route,
  title: { en, cs },
  icon: vector
}