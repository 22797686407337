function Decompose(v : number) : { mantissa : number, pwr10 : number } {
  if (isNaN(v)) return { mantissa: NaN, pwr10: 1 };

  let sign = v < 0 ? -1 : 1;
  v = sign * v;

  if (v === Infinity) return { mantissa: sign, pwr10: Infinity };

  let exp = 1;
  while (v / exp >= 1) exp *= 10;
  
  return { mantissa: sign * v / exp, pwr10: exp }
}

export function Round(v : number) {
  const val = Decompose(v);
  return val.pwr10 * 1e-4 * Math.round(val.mantissa * 1e4);
}

export function FormatNumber(v : number, compactSuffix = false) {
  if (isNaN(v)) return "-";
  let sign = v < 0 ? -1 : 1;
  v = sign * v;
//v*=1e6;
  let suffix = compactSuffix ? "" : " ";
  if (v > 1e3) {
    suffix = " k";
    v /= 1e3;
  }
  if (v > 1e3) {
    suffix = " M";
    v /= 1e3;
  }
  if (v > 1e3) {
    suffix = " G";
    v /= 1e3;
  }
  
  if (v < 10)
    return (sign * v).toFixed(3) + suffix;
  if (v < 100)
    return (sign * v).toFixed(2) + suffix;
  if (v < 1000)
    return (sign * v).toFixed(1) + suffix;
  return (sign * v).toFixed(0) + suffix;
}

export function UnscaledFormatNumber(v : number) {
  if (isNaN(v)) return "-";
  let sign = v < 0 ? -1 : 1;
  v = sign * v;

  if (v < 10)
    return (sign * v).toFixed(3);
  if (v < 100)
    return (sign * v).toFixed(2);
  if (v < 1000)
    return (sign * v).toFixed(1);
  return (sign * v).toFixed(0);
}

export function zfill(v : number) {
  return v.toString().padStart(2, "0");
}