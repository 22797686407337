import { tuple } from "../../FunnyTypes";

export type HarmSimple = `Harm_${"U_LL" | "U_LN" | "Current"}${"_Percent" | ""}`;
type Online = `Online${"_Min" | "_Max" | "_Avg" | "_Short" | ""}`
type HarmAll = `${HarmSimple}${"_Max" | "_Avg" | ""}`

export type Fetchable = Online | HarmAll | "Energy_Per_Day_Folder_Info" | "Energy_Folder_Info" | `Energy${"_Per_Day" | ""}_File` | "IO_State";
export type FetchOptions<T extends Fetchable>
= T extends `Energy${"_Per_Day" | ""}_File` ? { File_ID : number, File_Count : number, Tariff_Count : number }
: null;

export type SetOptions = {
  IO_State: { [k in `${keyof IO_State}_On`] : boolean }
}


export type DeviceInfo = {
  Name: string,
  Description: string,
  Firmware: string,
  Version: string,
  Mqtt_uid: string,
  Equipment: {
    RS485: boolean,
    Profibus: boolean,
    Data_flash: boolean,
    Virtual_flash: boolean,
    FRam: boolean,
    RJ45: boolean,
    Analog_output: boolean,
    Relay_outputs: number,
    Analog_inputs_outputs: number,
    Analog_outputs: number
  }
}

export function IsDeviceInfo(o: any) : o is DeviceInfo {
  return typeof o === "object"
      && typeof o.Name === "string"
      && typeof o.Description === "string"
      && typeof o.Firmware === "string"
      && typeof o.Version === "string"
      && typeof o.Mqtt_uid === "string"
      && typeof o.Equipment === "object"
      && typeof o.Equipment.RS485 === "boolean"
      && typeof o.Equipment.Profibus === "boolean"
      && typeof o.Equipment.Data_flash === "boolean"
      && typeof o.Equipment.Virtual_flash === "boolean"
      && typeof o.Equipment.FRam === "boolean"
      && typeof o.Equipment.RJ45 === "boolean"
      && typeof o.Equipment.Analog_output === "boolean"
      && typeof o.Equipment.Relay_outputs === "number"
      && typeof o.Equipment.Analog_inputs_outputs === "number"
      && typeof o.Equipment.Analog_outputs === "number"
}


export type ResponseHead = {
  Connection: string | "3UN_3I",
  Date_time: number,
  File_state: string | "Actual",
  File_type: Fetchable,
  Online_interval: number,
  Device: {
    Description: string,
    Firmware: string,
    Name: string,
    Version: string
  }
}

export function IsResponseHead(o: any) : o is ResponseHead {
  return typeof o === "object"
      && typeof o.Connection === "string"
      && typeof o.Date_time === "number"
      && typeof o.File_state === "string"
      && typeof o.File_type === "string"
      && typeof o.Online_interval === "number"
      && typeof o.Device === "object"
      && typeof o.Device.Description === "string"
      && typeof o.Device.Firmware === "string"
      && typeof o.Device.Name === "string"
      && typeof o.Device.Version === "string"
}


function IsN3(a: any) : a is [number, number, number] {
  return a instanceof Array && a.length === 3 && a.every(v => typeof v === "number");
}


export type energy = {Consumption: {Active_kWh: number, Inductive_kvarh: number, Capacity_kvarh: number}, Distribution: {Active_kWh: number, Inductive_kvarh: number, Capacity_kvarh: number}};

function IsEnergy(o: any) : o is energy {
  return typeof o === "object"
      && typeof o.Consumption === "object"
      && typeof o.Consumption.Active_kWh === "number"
      && typeof o.Consumption.Inductive_kvarh === "number"
      && typeof o.Consumption.Capacity_kvarh === "number"
      && typeof o.Distribution === "object"
      && typeof o.Distribution.Active_kWh === "number"
      && typeof o.Distribution.Inductive_kvarh === "number"
      && typeof o.Distribution.Capacity_kvarh === "number";
}

const shortKeys = [
  "CosFi", "CosFi_3F",
  "CosFi_Mod", "CosFi_3F_Mod",
  "Current", "Current_Zero",
  "Frequency",
  "P", "P_3F",
  "Q", "Q_3F",
  "S", "S_3F",
  "Temperature",
  "Thd_Current", "Thd_U_LL", "Thd_U_LN",
  "U_LL",
  "U_LN"
] as const;

const onlineMAMkeys = [
  "Angle_I", "Angle_U",
  "CosFi", "CosFi_3F",
  "CosFi_Mod", "CosFi_3F_Mod",
  "Pf", "Pf_3F",
  "Pf_Mod", "Pf_3F_Mod",
  "Current", "Current_Zero", "Distortion_Power",
  "Frequency", "KFactor",
  "P", "P_3F",
  "Q", "Q_3F", "S", "S_3F", "Tdd",
  "Temperature", "Thd_Current", "Thd_U_LL",
  "Thd_U_LN", "U_LL", "U_LN",
  "Unbalance_I_I0", "Unbalance_I_I2",
  "Unbalance_LN_U0", "Unbalance_LN_U2",

  "Rotation",
  "Over_Deviation_LL",
  "Over_Deviation_LN",
  "Under_Deviation_LL",
  "Under_Deviation_LN",
] as const;

export const energyKeys = [
  "Energy_T1", "Energy_T2", "Energy_T3", "Energy_T4",
  "Phase_energy_L1", "Phase_energy_L2", "Phase_energy_L3"
] as const;

export type OnlineData = { [_ in typeof onlineMAMkeys[number]] : [number, number, number] } & {
  Rotation : [number, number, number]
} & {[_ in typeof energyKeys[number]] : energy}

export type ShortOnlineData = { [_ in typeof shortKeys[number]]: tuple<number, 3> }

export function IsOnlineData(o: any) : o is OnlineData {
  return typeof o === "object"
      && onlineMAMkeys.every(k => IsN3(o[k]))
      && energyKeys.every(k => IsEnergy(o[k]));
}

export function IsShortOnlineData(o: any) : o is ShortOnlineData {
  return typeof o === "object"
      && shortKeys.every(k => IsN3(o[k]));
}

export type DayFile = {
  Status : "Success" | "Error",
  Firmware : string,
  ID_detail? : number,
  Date_time : number,
  Cleared : boolean,
  T1 : tuple<bigint, 6>,
  T2? : tuple<bigint, 6>,
  T3? : tuple<bigint, 6>,
  T4? : tuple<bigint, 6>,
}

type Option<T> = { success: true, value: T } | { success: false };
const FAIL = { success: false } as const;

const O = BigInt(0);
export const zero_6tupple = () => [O, O, O, O, O, O] as tuple<bigint, 6>;

function ParseCompactEnergy(o : any) : Option<tuple<bigint, 6>> {
  if (typeof o !== "object") return FAIL;
  let res = zero_6tupple();
  for (let i = 1; i <= 6; i++) {
    try {
      res[i - 1] = BigInt.asUintN(64, BigInt(`0x${o[i]}`));
    } catch {
      return FAIL;
    }
  }
  return { success: true, value: res };
}

function ParseDayFile(o : any) : Option<DayFile> {
  if (!(typeof o === "object"
    && (o.Status === "Success" || o.Status === "Error")
    && typeof o.Firmware === "string"
    && (typeof o.ID_detail === "number" || typeof o.ID_detail === "undefined")
    && typeof o.Date_time === "number"
    && typeof o.Cleared === "boolean"))
    return FAIL;
  
  let res = {...o} as DayFile;
  for (let k of ["T1", "T2", "T3", "T4"] as const) {
    if (o[k] === undefined) {
      if (k === "T1") return FAIL;
      continue;
    }
    if (!o.Cleared) {
      let opt = ParseCompactEnergy(o[k]);
      if (!opt.success) return FAIL;
      res[k] = opt.value;
    } else {
      res[k] = zero_6tupple();
    }
  }

  return { success: true, value: res };
}

type DayFiles = { [ k : number ] : DayFile };

export function ParseDayFiles(o : any) : Option<DayFiles> {
  if (typeof o !== "object") return FAIL;
  let res = {} as DayFiles;
  for (let k in o) {
    let opt = ParseDayFile(o[k]);
    if (!opt.success) return FAIL;
    res[k as any] = opt.value;
  }

  return { success: true, value: res };
}


type RState = {
  Type: "Alarm_output" | "Digital_output",
  Output_state: "Off" | "On"
};
type KState = {
  Type : "Pulse_input",
  Units : string,
  Value : number,
  Weight : number
} | {
  Type : "Digital_input",
  Input_state : "Off" | "On",
} | {
  Type: "Digital_output",
  Output_state: "Off" | "On"
} | { Type : | "Pulse_output" | "Tariff_input" };
type IO_State = {
  [r in `R${1|2|3}`] : RState
} & {
  [k in `K${1|2|3|4}`] : KState
}
function IsRState(o : any) : o is KState {
  return typeof o === "object"
      && true;  // TODO:
}
function IsKState(o : any) : o is KState {
  return typeof o === "object"
      && true;  // TODO:
}
export function IsIOState(o : any) : o is IO_State {
  return typeof o === "object"
      && IsRState(o.R1)
      && IsRState(o.R2)
      && IsRState(o.R3)
      && IsKState(o.K1)
      && IsKState(o.K2)
      && IsKState(o.K3)
      && IsKState(o.K4);
}


export type ParsableResponses = `Online${any}` | `Harm${any}` | `Energy${"_Per_Day" | ""}_Folder_Info` | `Energy${"_Per_Day" | ""}_File` | "IO_State";
type DataType<T extends ParsableResponses>
= T extends `Online${any}` ? T extends "Online_Short" ? ShortOnlineData : OnlineData
: T extends `Harm${any}` ? [number, number, number][]
: T extends `Energy${"_Per_Day" | ""}_Folder_Info` ? { Max_file_id : number, Min_file_id : number }
: T extends `Energy${"_Per_Day" | ""}_File` ? DayFiles
: T extends "IO_State" ? IO_State
: never;

export type RType<T extends Fetchable> = {
  Head : ResponseHead,
  Data : DataType<T>
}

type succ<T extends Fetchable> = { err: false, val: RType<T> };

export type err_key = "send_fail" | "timeout" | "canceled" | "parse_fail" | "unknown"
type fail = { err: true, kind: err_key, msg?: string }

export type FetchResult<T extends Fetchable> = succ<T> | fail;
export type SetResult = { err: false } | fail;

export enum MsgModes {
  Get = 0,
  Set = 1,
}

export type FetchMessage<T extends Fetchable>
= {
  topic : string,
  request : T,
  res : (val : FetchResult<T>) => void,
  options : FetchOptions<T>,
  mode : MsgModes.Get
};

export type SetMessage<K extends keyof SetOptions> = {
  topic : string,
  request : K,
  res : (val : SetResult) => void,
  options : SetOptions[K],
  mode : MsgModes.Set
}

export type Message = FetchMessage<Fetchable> | SetMessage<keyof SetOptions>;