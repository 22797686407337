export const RouterMap = {
  login: "/login",
  devices: "/devices",
  online: "/online",
  harmonics: "/harmonics",
  unitInfo: "/unitinfo",
  overview: "/overview",
  vector: "/vector",
  measurements: "/measurements",
  logout: "/logout",
  energy: "/energy",
  ioState: "/iostate"
} as const;

type a = typeof RouterMap[keyof typeof RouterMap];
type b = { [t in a] :
  { [k in keyof typeof RouterMap] : typeof RouterMap[k] extends t ? k : never }[keyof typeof RouterMap]
}
type c = { [t in keyof typeof RouterMap] :
  { [k in keyof b] : b[k] extends t ? k : never }[keyof b]
}[keyof typeof RouterMap]
type d = Exclude<a, c>;

// pokud tady je problém, hodnota obsažená v typu d se v RouterMap vyskytuje vícekrát.
let t : unknown = 1 as d;
