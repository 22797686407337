import React, { FC, ReactNode, useMemo } from "react";
import { Page } from "../../components/Page";
import { useLoc } from "../../functions/Language";
import { OverviewCard } from "../../components/OverviewCard";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { FormatNumber } from "../../functions/Formatting";
import { DvcPage, MeasurementPageList } from "../../components/navigation/PageList";
import { RouterMap } from "../../components/navigation/RouterMap";
import { fieldNames, units } from "../../GeneralTranslations";
import { OnlineData } from "../../functions/networking/NetTypes";
import capIcon from "../../img/capacity.svg";
import indIcon from "../../img/induction.svg";

const route = RouterMap.measurements;

const Overview : FC<{
  data: OnlineData
}> = (props) => {
  const loc = useLoc({en, cs});
  
  return <OverviewCard title={loc.title} href={route}>
    <MeasurementsTable {...props} />
  </OverviewCard>
}

function JalFormat(val : number) {
  if (val === 0) return "0.000";
  return <span style={{whiteSpace: "nowrap", marginLeft: "-4px"}}>
    <IonIcon icon={val > 0 ? indIcon : capIcon} style={{marginInline: "-1px"}} />
    {FormatNumber(Math.abs(val))}
  </span>
}

function ModCosFormat(val : number) {
  if (val === 0) return "0.000";
  let sign = val > 0 ? 1 : -1;
  val *= sign;
  if (val === 1) return "1.000"

  let cap = val * sign > sign;
  if (val > 1) val--;

  return <span style={{whiteSpace: "nowrap", marginLeft: "-4px"}}>
    <IonIcon icon={cap ? capIcon : indIcon} style={{marginInline: "-1px"}} />
    {FormatNumber(val)}
  </span>
}

export function SelectFormatter(type : keyof OnlineData) : (val : number) => ReactNode {
  switch (type) {
    case "Q":
      return JalFormat;
    case "CosFi_Mod":
    case "Pf_Mod":
      return ModCosFormat;
    default:
      return FormatNumber;
  }
}

const Row : FC<{
  loc : typeof fieldNames.cs,
  type : keyof typeof fieldNames.cs,
  sum? : { [k in keyof OnlineData] : OnlineData[k] extends number[] ? k : never }[keyof OnlineData],
  data : OnlineData
}> = (props) => {
  const formatter = useMemo<(val : number) => ReactNode>(() => SelectFormatter(props.type), [props.type]);

  return <IonRow>
    <IonCol size="3">{props.loc[props.type]} {units[props.type] && `[${units[props.type]}]`}</IonCol>
      <DataCols data={props.data[props.type]} formatter={formatter} />
    <IonCol>{props.sum && formatter(props.data[props.sum][0])}</IonCol>
  </IonRow>
}

export const MeasurementsTable : FC<{
  data : OnlineData
}> = (props) => {
  const fields = useLoc(fieldNames);

  return <IonGrid className="table tableb">
    <IonRow className="header" style={{borderTop : "solid 1px var(--ion-color-medium)"}}>
      <IonCol size="3"></IonCol>
      <IonCol>L1&nbsp;-&nbsp;L2</IonCol>
      <IonCol>L2&nbsp;-&nbsp;L3</IonCol>
      <IonCol>L3&nbsp;-&nbsp;L1</IonCol>
      <IonCol />
    </IonRow>
    <Row loc={fields} data={props.data} type="U_LL" />
    <IonRow className="header">
      <IonCol size="3"></IonCol>
      <IonCol>L1</IonCol>
      <IonCol>L2</IonCol>
      <IonCol>L3</IonCol>
      <IonCol>Σ</IonCol>
    </IonRow>
    <Row loc={fields} data={props.data} type="U_LN" />
    <Row loc={fields} data={props.data} type="Current" sum="Current_Zero" />
    <Row loc={fields} data={props.data} type="CosFi_Mod" sum="CosFi_3F_Mod" />
    <Row loc={fields} data={props.data} type="P" sum="P_3F" />
    <IonRow>
      <IonCol size="3">{fields.Q} [{units.Q}]</IonCol>
      <IonCol>{JalFormat(props.data.Q[0] * Math.sign(props.data.P[0]))}</IonCol>
      <IonCol>{JalFormat(props.data.Q[1] * Math.sign(props.data.P[1]))}</IonCol>
      <IonCol>{JalFormat(props.data.Q[2] * Math.sign(props.data.P[2]))}</IonCol>
      <IonCol>{JalFormat(props.data.Q_3F[0] * Math.sign(props.data.P_3F[0]))}</IonCol>
    </IonRow>
    <Row loc={fields} data={props.data} type="S" sum="S_3F" />
    <IonRow>
      <IonCol size="3">{fields.Frequency} [{units.Frequency}]</IonCol>
      <IonCol />
      <IonCol />
      <IonCol />
      <IonCol>{FormatNumber(props.data.Frequency[0])}</IonCol>
    </IonRow>
  </IonGrid>
}

const rows = ["P", "Q", "S"] as const;
export const DataCols : FC<{
  data: number[],
  formatter : (val : number) => ReactNode
}> = (props) => {
  return <>{props.data.map((v, i) => <IonCol key={i}>{props.formatter(v)}</IonCol>)}</>;
}

const Detail : FC = () => {
  const loc = useLoc({en, cs});

  return <Page title={loc.title}>
    <MeasurementPageList />
  </Page>
}

const en = {
  title: "Online values",
}

const cs : typeof en = {
  title: "Okamžité hodnoty",
}

export const Measurements = {
  Overview: Overview
}

export const PMeasurements : DvcPage = {
  page: Detail,
  path: route,
  title: {en, cs}
}