import { useEnergyData } from "./useEnergyData";
import { UnscaledFormatNumber } from "./Formatting";
import { utils, write } from 'xlsx';
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { Capacitor } from "@capacitor/core";

function Tabularise(data : ReturnType<typeof useEnergyData>) {
	let table : string[][] = [];

	let header = [""];
	for (let ds of data.datasets) {
		header.push(ds.label);
	}
	table.push(header);

	for (let i = 0; i < data.labels.length; i++) {
		let row = [data.labels[i]];
		for (let ds of data.datasets) {
			row.push(UnscaledFormatNumber(ds.data[i]));
		}
		table.push(row);
	}

	return table;
}

type TableRow = [string, ...(string | number)[]];
function TabulariseRaw(data : ReturnType<typeof useEnergyData>) {
	let header = [""];
	for (let ds of data.datasets) {
		header.push(ds.label);
	}
	let table : [string[], ...TableRow[]] = [header];

	for (let i = 0; i < data.labels.length; i++) {
		let row : TableRow = [data.labels[i]];
		for (let ds of data.datasets) {
			row.push(ds.data[i]);
		}
		table.push(row);
	}

	return table;
}

export async function SaveAsCSV(data: ReturnType<typeof useEnergyData>, name: string) {
	let table = Tabularise(data);
	let content = table.map(row => row.join(";")).join("\n")

	let blob = new Blob([content], { type: "text/csv" });
	await Save(blob, name);
}

export async function SaveAsXLSX(data: ReturnType<typeof useEnergyData>, name: string) {
	let table = TabulariseRaw(data);

	const sheet = utils.aoa_to_sheet(table);
	sheet["!cols"] = table[0].map(title => ({ wch : title.length }));
	sheet["!cols"][0].wch = Math.max(...table.map(row => row[0].length));

	const book = utils.book_new();
	utils.book_append_sheet(book, sheet);
	const buffer = write(book, { bookType: "xlsx", type: "array" });
	const blob = new Blob([buffer], { type: "application/octet-stream" });
	await Save(blob, name);
}

const SAVE_TIMEOUT = 5000;
async function Save(blob: Blob, name: string) {
	let platform = Capacitor.getPlatform();
	if (platform === "web") {
		const url = URL.createObjectURL(blob);
		setTimeout(() => { URL.revokeObjectURL(url); }, SAVE_TIMEOUT);

		let element = document.createElement('a');
		element.setAttribute('href', url);
		element.setAttribute('download', name);
		element.style.display = 'none';
		element.rel = 'noopener'
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	} else {
		// iPhone by to mel umet
		// android dela neprijemnosti
		let { uri: cacheURI } = await Filesystem.writeFile({
			data: await blobToBase64(blob),
			path: name,
			directory: Directory.Cache,
		});

		if (cacheURI) {
			Share.share({
				title: name,
				url: cacheURI
			});
		}
	}
}

function blobToBase64(blob: Blob) {
	return new Promise<string>((res, rej) =>
	{
		let reader = new FileReader();
		reader.onload = function ()
		{
			let dataUrl = reader.result as string | null;
			if (dataUrl) {
				let base64 = dataUrl.split(',')[1];
				res(base64);
			} else {
				rej();
			}
		};
		setTimeout(rej, SAVE_TIMEOUT);
		reader.readAsDataURL(blob);
	});
}
