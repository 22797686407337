import React, { FC, ReactNode, useEffect, useId, useRef, useState } from "react"
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonText } from "@ionic/react"
import { chevronDownOutline } from "ionicons/icons";

export const DropdownSelectItem : FC<{
  options : readonly ReactNode[],
  selected : number,
  setSelected : (o: number) => void,
  label : string,
  icon? : string
}> = (props) => {
  const [selected, setSelected] = useState(0);
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const ownId = useId()
  
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return <IonItem id={`select${ownId}`} detail detailIcon={chevronDownOutline} onClick={e => {e.preventDefault()}} href="">
    {props.icon === undefined ? <></> : <><IonIcon icon={props.icon} />&nbsp;</>}
    <IonLabel>{props.label}</IonLabel>
    <IonText>{props.options[props.selected]}</IonText>
    <IonPopover ref={popoverRef} trigger={`select${ownId}`} alignment="end" keepContentsMounted>
      <IonList>
        {props.options.map((v, i) =>
        <IonItem
          key={i}
          lines="full"
          color={selected === i ? "primary" : ""}
          onClick={e => { e.preventDefault(); props.setSelected(i); popoverRef.current?.dismiss(); }}
          href=""
        >
          <IonLabel>{v}</IonLabel>
        </IonItem>
        )}
      </IonList>
    </IonPopover>
  </IonItem>
}