import { MutableRefObject, useEffect, useReducer, useRef, useState } from "react";
import { Chart as ChartJS, Plugin } from "chart.js/auto";
import { IonRange } from "@ionic/react";
import { useLoc } from "../../functions/Language";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ZoomPluginOptions } from "chartjs-plugin-zoom/types/options";

export function useZoomer(
  count : number,
  setRange : (
    from : number,
    to : number,
    oldFrom : number,
    oldTo : number,
  ) => void,
  desiredRange : MutableRefObject<[number, number] | null>,
  resetDep? : unknown,
) {
  const loc = useLoc({ en: "Plot range", cs: "Rozsah grafu" });
  const chartRef = useRef<ChartJSOrUndefined>();

  const preview = useRef<[lower: number, upper: number]>([0, count - 1]);
  const [spreview, setSPreview] = useState(preview.current);
  const visible = useRef<[lower: number, upper: number]>([0, count - 1]);
  const [sliderValue, setSliderValue] = useState({ lower : visible.current[0], upper: visible.current[1] });

  function ApplyZoom(reset ?: boolean) {
    if (visible.current[0] === preview.current[0] && visible.current[1] === preview.current[1]) return;
    let old = visible.current;
    visible.current = [...preview.current];
    setSliderValue({ lower: preview.current[0], upper: preview.current[1] });
    setSPreview(preview.current);
    chartRef.current?.zoomScale("x", { min: visible.current[0], max: visible.current[1] });
    chartRef.current?.update();
    if (!reset)
      setRange(
        preview.current[0],
        preview.current[1],
        old[0],
        old[1]
      );
  }

  function Reset() {
    preview.current = desiredRange.current ?? [0, count - 1];
    ApplyZoom(true);
  }

  function Set(lower : number, upper : number) {
    preview.current = [lower, upper];
    ApplyZoom(true);
  }

  useEffect(Reset, [count, resetDep]);

  useEffect(() => {
    desiredRange.current = null;
  }, [resetDep]);

  const [slider, updateSlider] = useReducer(() => <IonRange
    label={loc}
    style={{
      paddingRight: 15,
    }}
    dualKnobs
    min={visible.current[0]} max={visible.current[1]} step={1}
    disabled={visible.current[0] >= visible.current[1]}
    value={sliderValue}

    onIonInput={e => {
      let val = e.detail.value as { lower: number, upper: number };
      if (preview.current[0] !== val.lower || preview.current[1] !== val.upper) {
        preview.current = [val.lower, val.upper];
        chartRef.current?.update();
        setSPreview(preview.current);
      }
    }}

    onIonChange={() => {
      ApplyZoom();
    }}
  />, <></>);
  useEffect(() => { updateSlider() }, [sliderValue]);

  const zoomOptions: ZoomPluginOptions = {
      zoom: {
        drag: {
          enabled: true,
          backgroundColor: "rgba(250, 100, 0, 0.3)",
        },
        mode: "x",
        onZoomComplete: (ctx) => {
          let axis = ctx.chart.scales["x"];
          preview.current = [axis.min, axis.max];
          ApplyZoom();
        },
      }
    }
  return {
    plugin: {
      id: "zoomPreview",
      install(chart, args, options) {
        chartRef.current = chart;
        chart.options.plugins!.zoom = zoomOptions;
      },
      beforeDraw(chart : ChartJS) {
        if (preview.current[0] < visible.current[0] || preview.current[1] > visible.current[1]) return;

        let yAxis = chart.scales.y;
        let xAxis = chart.scales.x;

        let s = xAxis.width / (visible.current[1] - visible.current[0] + 1);

        let x1 = xAxis.left + (preview.current[0] - visible.current[0]) * s;
        let x2 = xAxis.left + (preview.current[1] - visible.current[0] + 1) * s;
        let ctx = chart.ctx;

        ctx.save();

        ctx.fillStyle = "#cccccc";
        ctx.fillRect(xAxis.left, yAxis.top, x1 - xAxis.left, yAxis.height);
        ctx.fillRect(x2, yAxis.top, xAxis.right - x2, yAxis.height);

        ctx.restore();
      }
    } as Plugin,
    slider: slider,
    Reset,
    Set,
    preview: spreview
  }
}