import { IonButton, IonIcon, IonToast } from "@ionic/react";
import React, { FC, MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AddFetchListener, Fetch, FetchListener, RemoveFetchListener } from "./Networking";
import { reloadOutline } from "ionicons/icons";
import { RefreshContext } from "../../App";
import { useLoc } from "../Language";
import { FetchOptions, Fetchable, RType, err_key } from "./NetTypes";

type Autoloadable = {
  [ T in Fetchable ] : FetchOptions<T> extends null ? T : never
}[Fetchable];

export function useLoader<T extends Autoloadable, D>(
  request : T,
  placeholder : D,
  disabled = false,
  refr : number | undefined = undefined,
  onManualReload : () => void = () => {},
  watchOthers = false
) {
  const refresh = refr ?? useContext(RefreshContext);
  const mounted = useRef(false);
  const [loading, setLoading] = useState(1);
  const [data, setData] = useState<RType<T> | D>(placeholder);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<{kind: err_key, msg?: string} | null>(null);
  const nextLoadAfter = useRef<number>(new Date().valueOf());

  const listener = useRef<FetchListener<T>>({ request, handler(val) {
    setData(val);
    
    // možná, že tohle bude dělat neplechu při zobrazování detailu erroru, halvně u timeoutu
    setError(null);
  } })

  useEffect(() => {
    mounted.current = true;
    if (watchOthers)
      AddFetchListener(listener.current);
    return () => {
      mounted.current = false;
      RemoveFetchListener(listener.current);
    }
  }, []);

  useEffect(() => {
    if (disabled)
      setLoading(0);
    else {
      setLoading(v => v + 1);
    }
  }, [disabled])

  useEffect(() => {
    if (refresh > 0 && !disabled) {
      let requestInProgress = false;

      let cancel = SetAlignedInterval(async () => {
        if (requestInProgress || !mounted.current) return;
        setLoading(v => v + 1);
      }, refresh * 1e3, nextLoadAfter);

      return () => {
        cancel.current();
      }
    } else if (disabled) {
      setLoading(0);
    }
  }, [refresh, disabled, request]);

  useEffect(() => {
    if (loading === 0 || disabled)
      return;
    
    setError(null);

    (async () => {
      let val = await Fetch<Autoloadable>(request, null);
      if (mounted.current) {
        if (val.err) {
          setLoaded(false);
          setError(val);
        } else {
          setData(val.val);
        }
        setLoading(0);
      }
    })();
  }, [loading]);

  return {
    data,
    loaded,
    loading,
    reload(manual = true) {
      if (!disabled) {
        setLoading(v => v + 1);
        if (manual)
          onManualReload();
      }
    },
    error,
    pause(ms: number) { // remember to unpause by `pause(0)`
      nextLoadAfter.current = ms < 0 ? new Date().setFullYear(9998) : (new Date().valueOf() + ms);
    }
  }
}

export const ReloadButton : FC<{
  loaders : (ReturnType<typeof useLoader>)[]
}> = (props) => {
  const loc = useLoc({ en, cs });
  const eloc = useLoc({ en: en_err, cs: cs_err });
  const failed = useMemo<typeof props.loaders>(
    () => {
      return props.loaders.filter(l => l.error !== null);
    },
    [props.loaders]
  );
  const [showErr, setShowErr] = useState(false);
  const [errDetail, setErrDetail] = useState(false);
  const errCloser = useRef<ReturnType<typeof setTimeout> | null>(null);

  function CancelErrClose() {
    if (errCloser.current !== null) {
      clearTimeout(errCloser.current);
      errCloser.current = null;
    }
  }

  //useEffect(() => CancelErrClose, [])

  useEffect(() => {
    if (failed.length > 0) {
      setShowErr(true);
      failed[0]?.pause(5000)
      errCloser.current = setTimeout(() => {
          if (errCloser.current !== null)
            setShowErr(false);
        }, 4000);
    } else {
      setShowErr(false);
    }

    return CancelErrClose;
  }, [failed.length]);

  return <>
    <IonButton onClick={() => props.loaders.forEach(l => l.reload())}>
      {props.loaders.some(l => l.loading)
      ? <IonIcon icon={reloadOutline} id="wtf" className={"spin"} style={{"--tx": "0.6px", "--ty": "-0.023px"}} />
      : <IonIcon icon={reloadOutline} />
      }
    </IonButton>
    
    <IonToast
      style={{"--white-space": "pre-line", "--button-color": "var(--ion-color-secondary)"}}
      isOpen={showErr}
      position="top"
      message={errDetail && failed[0]?.error
        ? `${loc.error}\n${eloc[failed[0].error.kind]}${failed[0].error?.msg === undefined ? "" : "\n" + failed[0].error.msg}`
        : loc.error}
      layout="stacked"
      buttons={errDetail ? [
        {
          text: loc.close,
          role: "cancel"
        }
      ] : [
        {
          text: loc.more,
          role: "expand",
          handler() {
            CancelErrClose();
            setErrDetail(true);
            failed[0]?.pause(-1);
            return false;
          },
          cssClass: "secButton"
        },
        {
          text: loc.close,
          role: "cancel",
        },
      ]}
      onDidDismiss={() => {
        setErrDetail(false);
        CancelErrClose();
        setShowErr(false);
        failed[0]?.pause(0);
      }}
    />
  </>
}

const en = {
  error: "Loading failed.",
  more: "Show details",
  close: "Close"
}

const cs : typeof en = {
  error: "Načítání selhalo.",
  more: "Zobrazit detaily",
  close: "Zavřít"
}

const en_err : { [_ in err_key] : string } = {
  canceled: "Request was canceled",
  parse_fail: "Failed to parse response",
  send_fail: "Failed to send request",
  timeout: "Request timed out",
  unknown: "Unknown error"
}

const cs_err : typeof en_err = {
  canceled: "Požadavek byl zrušen",
  parse_fail: "Nepovedlo se přečíst odpověď",
  send_fail: "Nepovedlo se odeslat požadavek",
  timeout: "Časový limit požadavku vypršel",
  unknown: "Neznámá chyba"
}

function SetAlignedInterval(callback: () => PromiseLike<void>, ms: number, soonest: MutableRefObject<number>) {
  let canceled = false;
  const cancel = { current : () => { canceled = true } };

  (async () => {
    while (!canceled) {
      await callback();
      // zdržování kvůli chybové hlášce
      do await new Promise<void>(res => {
          let ref = setTimeout(res, ms - (new Date().valueOf() % ms));
          cancel.current = () => {
            canceled = true;
            clearTimeout(ref);
            res();
          }
        });
      while (new Date().valueOf() < soonest.current)
    }
  })();

  return cancel;
}