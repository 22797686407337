import React, { IonItem } from "@ionic/react";
import { CategoryScale, ChartData, ChartOptions, defaults } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import { FC, useEffect, useMemo, useReducer, useRef } from "react";
import { Line } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { FormatNumber, Round, zfill } from "../../functions/Formatting";

ChartJS.register(CategoryScale);

function formatDate(v: Date) {
  return `${v.getHours()}:${zfill(v.getMinutes())}:${zfill(v.getSeconds())}`
}

const colors = ["#ff0000", "#00ee00", "#3f3fff"];
const lineColors = ["rgba(255,0,0,.4)", "rgba(0,238,0,.4)", "rgba(63,63,255,.4)"];

function DataSet(data: [number, number, number][], i: number, phaseNames: string[], times: Date[])
: (ChartData<"line"> extends { datasets : (infer T)[] } ? T : never)
{
  return {
    label: phaseNames[i],
    backgroundColor: colors[i - 1],
    data: data.map((v, j) => {
      let val = Math.abs(v[i - 1]);
      if (val <= 1) val = 3 - val;
      
      return {
        x: Math.floor(times[j].valueOf() / 1000),
        y: Round(val),
        label: formatDate(times[j])
      }
    }),
    borderColor: lineColors[i - 1],
  }
}

function YTicks(y : number) {
  return y >= 2 ? 3 - y : y - 1;
}

export const CosPhiChart : FC<{
  data: [number, number, number][],
  phaseNames: string[],
  forceRedraw?: unknown,
  times: Date[],
  generation: unknown,
}> = (props) => {
  const chart = useRef<ChartJSOrUndefined<"line">>();
  const data = useMemo<ChartData<"line">>(() => ({
    datasets: [
      DataSet(props.data, 1, props.phaseNames, props.times),
      DataSet(props.data, 2, props.phaseNames, props.times),
      DataSet(props.data, 3, props.phaseNames, props.times),
    ]
  }), [props.data, props.forceRedraw, props.times]);
  const [vis, updateVis] = useReducer(v => v + 1, 0);
  const mod = useRef(5);
  const propsRef = useRef(props);
  useEffect(() => { propsRef.current = props }, [props]);
  
  useEffect(() => {
    const duration = props.times.length > 0 ? props.times[props.times.length - 1].valueOf() - props.times[0].valueOf() : 1;
    mod.current = duration > 150e3 ? 120 : duration > 40e3 ? 30 : 5;
  }, [props.generation]);

  const options = useRef<ChartOptions<"line">>({
    animation: false,
    clip: false,
    scales: {
      x: {
        type: "linear",
        ticks: {
          autoSkip: false,
          stepSize: 1,
          callback (tick, index) {
            if (typeof tick === "string")
              return tick;
            let time = new Date(tick * 1000);
            if (tick % mod.current !== 0)
              return;
            return formatDate(time);
          }
        },
        grid: {
          offset: false,
        }
      },
      y: {
        ticks: {
          callback (v) {
            if (typeof v === "string") return v;
            return YTicks(v).toFixed(1) + (v > 2 ? " (L)" : v < 2 ? " (C)" : "");
          },
          stepSize: .2,
        },
        min: 1,
        max: 3,
      }
    },
    plugins: {
      legend: {
        onClick(e, li, l) {
          updateVis();
          (defaults.plugins as any).legend.onClick(e, li, l);
        },
        display: propsRef.current.phaseNames.length > 1
      },
      tooltip: {
        intersect: false,
        axis: "x",
        position: "average",
        mode: "index",
        callbacks: {
          label: (ctx : any) => {
            let label = ctx.dataset.label;
            if (label) label += ": "
            else label = "";
            if (ctx.parsed.y !== null) {
              label += FormatNumber(YTicks(ctx.parsed.y)) + (ctx.parsed.y > 2 ? " (L)" : ctx.parsed.y < 2 ? " (C)" : "");
            }
            return label;
          },
          title(tooltipItems) {
            return (tooltipItems[0].raw as any).label;
          }
        }
      }
    }
  });

  useEffect(() => {
    if (chart.current?.options.plugins?.legend) {
      chart.current.options.plugins.legend.display = propsRef.current.phaseNames.length > 1;
      chart.current.update();
    }
  }, [props.phaseNames]);

  useEffect(() => {
    if (props.times.length > 0) {
      options.current.scales!.x!.min = Math.floor(props.times[0].valueOf() / 1000);
      options.current.scales!.x!.max = Math.floor(props.times[props.times.length - 1].valueOf() / 1000);
    }

    chart.current!.options = options.current;
    chart.current?.update();
  }, [data, vis]);

  return <><IonItem lines="none">
    <div style={{width: "calc(min(100%, 135vh))"}}>
      <div style={{paddingTop: "50%"}}></div>
      <Line
        style={{position: "absolute", top: "0"}}
        ref={chart}
        data={data}
        options={options.current}
        plugins={[{
          id: "cursor",
          beforeDraw(chart) {
            if ((chart.tooltip as any)?._active?.length) {
              let x = (chart.tooltip as any)._active[0].element.x;
              let yAxis = chart.scales.y;
              let ctx = chart.ctx;
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, yAxis.top);
              ctx.lineTo(x, yAxis.bottom);
              ctx.lineWidth = 1;
              ctx.strokeStyle = '#ff7f00';
              ctx.stroke();
              ctx.restore();
            }
          }
      }]}
      />
    </div>
    </IonItem>
  </>
}