import { IonButton, IonCol, IonContent, IonIcon, IonItem, IonList, IonRow } from "@ionic/react";
import { CategoryScale, ChartData, ChartOptions, Tooltip } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { FC, MutableRefObject, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { tuple } from "../../FunnyTypes";
import { UnscaledFormatNumber, zfill } from "../../functions/Formatting";
import { useLoc } from "../../functions/Language";
import { EnergyData, Resolution } from "../../functions/networking/EnergyLoading";
import { EnergyLegend, energyUnits, powerUnits } from "./EnergyLegend";
import { useZoomer } from "./Zoomer";
import { returnUpBack, returnUpBackOutline } from "ionicons/icons";

ChartJS.register(CategoryScale, zoomPlugin);
// @ts-ignore
(Tooltip.positioners).bottom = function(items, epos) {
  // @ts-ignore
  const pos = Tooltip.positioners.average(items, epos);

  // Happens when nothing is found
  if (pos === false) {
    return false;
  }

  // @ts-ignore
  const chart = this.chart;

  return {
    x: pos.x,
    y: chart.chartArea.bottom,
    yAlign: 'bottom',
  };
};

export const EnergyChart : FC<{
  data : EnergyData,
  resolution : Resolution,
  hidden? : boolean,
  majorDataVersion ?: unknown,
  rangeGen ?: unknown,
  power ?: boolean,
  edata : ChartData<"bar">
} & ({
  simple : true
} | {
  simple? : false,
  updateRes : (res : Resolution, start : Date, end : Date) => void,
  start : Date,
  end : Date,
})> = (props) => {
  // tohle je potřeba, aby se správně generovaly popisy dat v grafu
  const propsRef = useRef(props);
  useEffect(() => { propsRef.current = props }, [props]);

  const loc = useLoc({ en, cs });

  const zoomStack = useRef<{
    start : Date,
    startIndex : number,
    end : Date,
    endIndex : number,
    res : Resolution
  }[]>([]);
  useEffect(() => {
    zoomStack.current = [];
  }, [props.rangeGen]);
  
  const chart = useRef<ChartJSOrUndefined<"bar">>();

  const desiredRange = useRef<[number, number] | null>(null);
  const zoomer = useZoomer(
    props.data.length,
    function (lower, upper, oldLow, oldHigh) {
      if (propsRef.current.simple || lower > upper) return;

      zoomStack.current.push({
        start: propsRef.current.start,
        end: propsRef.current.end,
        res: propsRef.current.resolution,
        startIndex: oldLow,
        endIndex: oldHigh
      });

      if (
        (upper - lower >= 2
          && (
            propsRef.current.resolution !== Resolution.hour
            || upper - lower >= 12
          )
        )
        || propsRef.current.resolution === Resolution.qh
      ) return;

      let res : Resolution = propsRef.current.resolution;
      let start = new Date(propsRef.current.data[lower].time.valueOf());
      let end = new Date(propsRef.current.data[upper].time.valueOf());
      switch (propsRef.current.resolution) {
        case Resolution.hour:
          res = Resolution.qh;
          end.setHours(end.getHours() + 1);
          break;
        case Resolution.day:
          res = Resolution.hour;
          start.setHours(start.getHours());
          end.setHours(end.getHours());
          end.setDate(end.getDate() + 1);
          break;
        case Resolution.month:
          res = Resolution.day;
          end.setMonth(end.getMonth() + 1, 0);
          break;
        default: { let a : never = propsRef.current.resolution; throw `Unkown resolution: ${a}`; }
      }
  
      propsRef.current.updateRes(res, start, end);
    },
    desiredRange,
    props.majorDataVersion
  );

  const options = useRef<ChartOptions<"bar">>({
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        ticks: {
          align: props.resolution === Resolution.qh || props.resolution === Resolution.hour ? "end" : "center"
        }
      },
      y: {
        ticks: {
          callback: v => typeof v === "string" ? v : UnscaledFormatNumber(v)
        },
        grace: "5%"
      },
    },
    // @ts-ignore
    plugins: props.simple ? {
      legend: {
        display: false
      },
      tooltip: {
        intersect: false,
        axis: "x",
        position: "bottom",
        mode: "index",
        callbacks: {
          label(item) {
            let label = item.dataset.label;
            if (label) label += ": ";
            else label = "";
            if (item.parsed.y !== null) {
              label += UnscaledFormatNumber(item.parsed.y) + " " + (propsRef.current.power ? powerUnits : energyUnits)[item.datasetIndex % 3];
            }
            return label;
          }
        }
      },
    } : {
      legend: {
        display: false
      },
      tooltip: {
        intersect: false,
        axis: "x",
        position: "bottom",
        mode: "index",
        callbacks: {
          title(tooltipItems) {
            if (tooltipItems.length === 0 || propsRef.current.data.length === 0) return;
            let start = new Date(propsRef.current.data[0].time);
            switch (propsRef.current.resolution) {
              case Resolution.qh: {
                start.setTime(start.getTime() + tooltipItems[0].dataIndex * 9e5);
                // start.setMinutes(start.getMinutes() + tooltipItems[0].dataIndex * 15);
                let end = new Date(start.valueOf() + 900e3);
                return `${loc.dayLabel(start)} ${start.getFullYear()}, ${zfill(start.getHours())}:${zfill(start.getMinutes())} - ${zfill(end.getHours())}:${zfill(end.getMinutes())}`;
              }
              case Resolution.hour: {
                start.setHours(start.getHours() + tooltipItems[0].dataIndex);
                let end = new Date(start.valueOf() + 3600e3);
                return `${loc.dayLabel(start)} ${start.getFullYear()}, ${zfill(start.getHours())}:00 - ${zfill(end.getHours())}:00`;
              }
              case Resolution.day: {
                start.setDate(start.getDate() + tooltipItems[0].dataIndex);
                return `${tooltipItems[0].label} ${start.getFullYear()}`;
              }
              case Resolution.month:
                return tooltipItems[0].label;
            }
          },
          label(item) {
            let label = item.dataset.label;
            if (label) label += ": ";
            else label = "";
            if (item.parsed.y !== null) {
              label += UnscaledFormatNumber(item.parsed.y) + " " + (propsRef.current.power ? powerUnits : energyUnits)[item.datasetIndex % 3];
            }
            return label;
          }
        }
      },
    }
  });

  function Unzoom() {
    let zoom = zoomStack.current.pop();
    if (zoom && !propsRef.current.simple) {
      if (zoom.res !== propsRef.current.resolution) {
        desiredRange.current = [zoom.startIndex, zoom.endIndex];
        propsRef.current.updateRes(zoom.res, zoom.start, zoom.end);
      } else {
        zoomer.Set(zoom.startIndex, zoom.endIndex);
      }
    } else {
      zoomer.Reset();
    }
  }

  let rangeEnd = props.data[zoomer.preview[1]]?.time as Date | undefined;
  if (props.resolution === Resolution.month && rangeEnd) {
    rangeEnd = new Date(rangeEnd.valueOf());
    rangeEnd.setMonth(rangeEnd.getMonth() + 1, 0);
  }

  return <IonList style={{ height: "100%", display: props.hidden ? "none" : "flex", flexDirection: "column" }}>
    <IonItem lines="none">
      <div style={{width: "100%", marginInline: "auto"}}>
        <EnergyLegend chart={chart.current} power={props.power} />
      </div>
    </IonItem>
    <IonItem lines="none" style={{ flex: "auto", "--min-height": "100%" }}>
      <div style={{ width: "100%", marginInline: "auto", height: "100%" }}>
        <Bar
          ref={chart}
          data={props.edata}
          onDoubleClick={Unzoom}
          options={options.current}
          plugins={[zoomer.plugin]}
        />
      </div>
    </IonItem>
   
    {props.simple ? <></> : <>
      <IonItem lines="none">
        {zoomer.slider}
        <IonButton onClick={Unzoom}>
          <IonIcon icon={returnUpBackOutline} />
        </IonButton>
      </IonItem>
      <IonItem lines="full">
        <IonCol>{loc.dateLabel(props.data[zoomer.preview[0]]?.time ?? new Date())}</IonCol>
        <IonCol style={{textAlign: "end"}}>{loc.dateLabel(rangeEnd ?? new Date())}</IonCol>
      </IonItem>
    </>}
  </IonList>
}

const en = {
  monthNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  monthLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getFullYear()}`;
  },
  dayLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getDate()}`;
  },
  dateLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getDate()} ${d.getFullYear()}`
  }
}

const cs : typeof en = {
  monthNames : ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
  monthLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getFullYear()}`;
  },
  dayLabel(d : Date) {
    return `${d.getDate()}. ${d.getMonth() + 1}.`;
  },
  dateLabel(d : Date) {
    return `${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()}`
  }
}
