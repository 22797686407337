import { useMemo } from "react";
import { EnergyData, Resolution } from "./networking/EnergyLoading";
import { ChartData } from "chart.js";
import { useLoc } from "./Language";
import { zfill } from "./Formatting";
import { tuple } from "../FunnyTypes";

const colors = ["#ff0000", "#00ee00", "#3f3fff", "#00cfff", "#ff11ff", "#c0c000"];

function CreateLabels(
  data : EnergyData,
  res : Resolution,
  loc : {
    dayLabel : (d : Date) => string,
    monthLabel : (d : Date) => string,
  }
) {
  switch (res) {
    case Resolution.qh:
      return data.map(v => 
        `${zfill(v.time.getHours())}:${zfill(v.time.getMinutes())}`);
    case Resolution.hour:
      return data.map(v => `${zfill(v.time.getHours())}:00`);
    case Resolution.day:
      return data.map(v => loc.dayLabel(v.time));
    case Resolution.month:
      return data.map(v => loc.monthLabel(v.time));
    default: { let a: never = res; throw (`Wrnog resolution ${res}`) }
  }
}

export function useEnergyData(data: EnergyData, resolution: Resolution, quantityNames: tuple<string, 6>, power?: boolean, forceRedraw?: unknown) {
	const loc = useLoc({ en, cs });
	const edata = useMemo<
    ChartData<"bar"> & { datasets: { label: string, data: number[] }[], labels: string[] }
  >(() =>
	({
		datasets: quantityNames.map((label, i) => ({
			label,
			backgroundColor: colors[i],
			data: data.map(
				power && resolution !== Resolution.hour
					? (resolution === Resolution.qh
						? v => v.values[i] * 4
						: v => {
							let start = new Date(v.time.valueOf());
							start.setHours(0, 0, 0, 0);
							let end = new Date(v.time.valueOf());
							end.setHours(0, 0, 0, 0)

							if (resolution === Resolution.month) {
								start.setDate(1);
								end.setMonth(end.getMonth() + 1, 1);
							} else {
								end.setDate(end.getDate() + 1);
							}

							return v.values[i] / ((end.valueOf() - start.valueOf()) / 36e5);
						})
					: v => v.values[i]
			)
		})),
		labels: CreateLabels(data, resolution, loc)
	}), [data, forceRedraw, loc, resolution, power, quantityNames]);

	return edata;
}

const en = {
  monthNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  monthLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getFullYear()}`;
  },
  dayLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getDate()}`;
  },
  dateLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getDate()} ${d.getFullYear()}`
  }
}

const cs : typeof en = {
  monthNames : ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
  monthLabel(d : Date) {
    return `${this.monthNames[d.getMonth()]} ${d.getFullYear()}`;
  },
  dayLabel(d : Date) {
    return `${d.getDate()}. ${d.getMonth() + 1}.`;
  },
  dateLabel(d : Date) {
    return `${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()}`
  }
}