import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonPopover, IonRow, IonSelect, IonSelectOption, IonText, IonToast } from "@ionic/react";
import React, { FC, useEffect, useReducer, useRef, useState } from "react";
import { Page } from "../../components/Page";
import { useLoc } from "../../functions/Language";
import { LoginFailureModes, login } from "../../functions/networking/Networking";
import { useHistory } from "react-router";
import { RouterMap } from "../../components/navigation/RouterMap";
import { Preferences } from "@capacitor/preferences";
import { PrefKey } from "../../components/AppSettings";

type Profile = {
  name : string,
  password : string,
  save : boolean,
  savePwd : boolean
}

export const LoginPage : FC<{
  setLoggedIn: (val: boolean) => void
}> = (props) => {
  const loc = useLoc({en, cs});
  const nameInput = useRef<HTMLIonInputElement>(null);
  const pwdInput = useRef<HTMLIonInputElement>(null);
  const hist = useHistory();
  const [err, setErr] = useState<string | null>(null);
  const [externalSite, setExternalSite] = useState("");
  
  const psel = useRef<HTMLIonSelectElement>(null);
  const [, redraw] = useReducer((v : number) => v + 1, 0);
  const [showForget, setShowForget] = useState(false);
  const profiles = useRef<Profile[]>([{
    name: "",
    password: "",
    savePwd: false,
    save: false,
  }]);
  const pid = useRef(0);

  const [users, setUsers] = useState([emptyProfile()]);
  const [selUser, setSelUser] = useState("");
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    Preferences.get({ key: "users" as PrefKey }).then(({ value }) => {
      if (!mounted.current) return;
      if (value !== null) setUsers(ParseUsers(value));
    });
    Preferences.get({ key: "selUser" as PrefKey }).then(({ value }) => {
      if (!mounted.current) return;
      if (value !== null) setSelUser(value);
    });

    return () => {
      mounted.current = false;
    }
  }, []);
  
  useEffect(() => {
    Preferences.set({ key: "users" as PrefKey, value : StringifyUsers(users) });
    profiles.current = users;
  }, [users]);

  useEffect(() => {
    Preferences.set({ key: "selUser" as PrefKey, value : selUser });
  }, [selUser]);

  useEffect(() => {
    pid.current = Math.max(0, users.findIndex(u => u.name === selUser));
    setSelUser(users[pid.current].name);
    redraw(); //?
  }, [users, selUser]);

  return <Page title={loc.title} hideBackButton hideNav loggedOut>
    <div style={{ height: "100%", marginBottom: "0px", marginTop: "0px" }}>
    <IonCard style={{maxWidth: "40em", margin: "auto", minHeight: "100%"}}>
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 1000,
        backgroundColor: "var(--background)",
        overflow: "hidden",
        display: externalSite.length ? "flex" : "none",
        flexDirection: "column"
      }}>
        <IonRow>
          <IonButton style={{flex: 1}} onClick={()=>setExternalSite("")} expand="block">{loc.returnToLogin}</IonButton>
          <IonButton style={{flex: 1}} href={externalSite} expand="block" onClick={()=>setExternalSite("")} target="_blank">{loc.openInBrowser}</IonButton>
        </IonRow>
      <iframe src={externalSite} style={{flexGrow: 1, border: "none", margin: 0, padding: 0}}></iframe>
    </div>

    <form onSubmit={e => e.preventDefault()}>
      <IonItem lines="none">
        <IonLabel>{loc.selUser}</IonLabel>
        <IonSelect ref={psel} value={pid.current} onIonChange={(ev) => {
            pid.current = ev.detail.value;
            redraw();
          }}>
          {profiles.current.map((v, i) => <IonSelectOption value={i} key={i}>
            {v.name === "" ? loc.newUser : v.name}
          </IonSelectOption>)}
        </IonSelect>
      </IonItem>

    <IonItem lines="none">
      <IonLabel position="stacked">{loc.name}</IonLabel>
      <IonInput
        className="inputlogin"
        type="text"
        ref={nameInput}
        legacy required
        value={profiles.current[pid.current].name}
        onIonChange={e => {
          profiles.current[pid.current].name = (e.detail.value!).toLowerCase();
          if (profiles.current[profiles.current.length - 1].name !== "") {
            profiles.current.push({ name: "", password: "", save: false, savePwd: false });
          }
        }}
      />
    </IonItem>
    <IonItem lines="none">
      <IonLabel position="stacked">{loc.password}</IonLabel>
      <IonInput className="inputlogin" type="password" ref={pwdInput} required
        value={profiles.current[pid.current].password}
        onIonChange={e => profiles.current[pid.current].password = e.detail.value!}
      />
    </IonItem>

    <IonItem lines='none'>
      <IonLabel>{loc.rememberName}</IonLabel>
      <IonCheckbox
        checked={profiles.current[pid.current].save}
        onIonChange={ev => {
          profiles.current[pid.current].save = ev.detail.checked;
          if (!ev.detail.checked) {
            profiles.current[pid.current].savePwd = false;
            if (profiles.current[pid.current].name !== "")
              setShowForget(true);
            //redraw();
          }
        }}
      />
    </IonItem>
    <IonItem lines='none'>
      <IonLabel>{loc.rememberPassword}</IonLabel>
      <IonCheckbox
        checked={profiles.current[pid.current].savePwd && profiles.current[pid.current].save}
        onIonChange={ev => {
          profiles.current[pid.current].savePwd = ev.detail.checked;
          if (ev.detail.checked) {
            profiles.current[pid.current].save = true;
            redraw();
          }
        }}
      />
    </IonItem>

    <IonItem lines="full">
      <IonButton type="submit" style={{width: "100%", height: "3em", marginInline: 0, marginTop: "1em", "--border-radius": "6px"}} onClick={async () => {
        const status = await login(((nameInput.current?.value ?? "") as string).toLowerCase(), pwdInput.current?.value ?? "");
        props.setLoggedIn(status.succ);
        if (status.succ) {
          setErr(null);
          Preferences.set({ key: "users" as PrefKey, value : StringifyUsers(profiles.current) });
          Preferences.set({ key: "selUser" as PrefKey, value : profiles.current[pid.current].name ?? "" });
          hist.push(RouterMap.devices);
        } else {
          setErr(status.mode === LoginFailureModes.connection ? loc.connectionFailed : loc.loginFailed);
        }
      }}>{loc.login}</IonButton>
    </IonItem>
    </form>

    <div style={{marginTop: "2em"}}>
      <ExternalLink 
        text={loc.verifyAccount}
        href="https://mqtt.bmr.cz/account-verification.php"
        setExternalSite={setExternalSite}
      />
      <ExternalLink
        text={loc.createAccount}
        href="https://mqtt.bmr.cz/account-register.php"
        setExternalSite={setExternalSite}
      />
      <ExternalLink
        text={loc.resetPassword}
        href="https://mqtt.bmr.cz/password-reset.php"
        setExternalSite={setExternalSite}
      />
      <ExternalLink
        text={loc.deleteAccount}
        href="https://mqtt.bmr.cz/account-unregister.php"
        setExternalSite={setExternalSite}
      />
    </div>

    <IonToast
      isOpen={err !== null}
      duration={5000}
      onWillDismiss={() => setErr(null)}
      message={err ?? ""}
      position="top"
      buttons={[{
        text: "OK",
        role: "cancel"
      }]}
    />
    </IonCard>
    </div>

    <IonPopover isOpen={showForget} onDidDismiss={() => setShowForget(false)} /*class='popover'*/>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{loc.forgetrn}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            <IonCol>
              <IonButton expand='block' onClick={() => setShowForget(false)}>{loc.no}</IonButton>
            </IonCol>
            <IonCol>
              <IonButton color='danger' expand='block' onClick={() => {
                setUsers(profiles.current.filter((_, i) => i !== pid.current));
                setSelUser(profiles.current[0].name);
                setShowForget(false);
                //psel.current!.forceUpdate();
              }}>{loc.yes}</IonButton>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </IonPopover>
  </Page>
}

const ExternalLink : FC<{
  href : string,
  text : string,
  setExternalSite : (href : string) => void
}> = (props) =>
<IonItem lines='none' style={{height: "2em"}}>
  <small>
    <a
      href={props.href}
      onClick={ev => {
        ev.preventDefault();
        props.setExternalSite(props.href);
      }}
    >{props.text}</a>
  </small>
</IonItem>


function ParseUsers(json : string, successRef? : { val : boolean }) : Profile[] {
  let res : Profile[] = [];
  if (successRef)
    successRef.val = false;

  try {
    let obj = JSON.parse(json);
    let profs = Object.keys(obj).map(name => ({
      name: name,
      password: atob(obj[name]),
      save: true,
      savePwd: obj[name] !== ""
    }));

    for (let i = 0; i < profs.length; i++) {
      if (res.every(p => p.name !== profs[i].name))
        res.push(profs[i]);
    }
    if (successRef)
      successRef.val = true;
  } catch {
    console.error("Failed to load profiles");
  }

  res.push(emptyProfile());
  return res;
}

function StringifyUsers(users : Profile[]) {
  let res : { [k : string] : string } = {};
  for (let i = 0; i < users.length; i++) {
    if (users[i].save)
      res[users[i].name] = users[i].savePwd ? btoa(users[i].password) : "";
  }
  return JSON.stringify(res);
}

export const emptyProfile : () => Profile = () => ({
  name: "",
  password: "",
  savePwd: false,
  save: false,
});


const en = {
  name: "User name",
  password: "Password",
  login: "Log in",
  title: "Power Grid Monitor",

  verifyAccount: "Account verification",
  resetPassword: "Forgotten password",
  deleteAccount: "Delete account",
  createAccount: "Create account",

  connectionFailed: "Could not connect to the MQTT broker",
  loginFailed: "Incorrect username or password",

  returnToLogin: "Return to login",
  openInBrowser: "Open in browser",

  rememberName: "Save user name",
  rememberPassword: "Save password",
  forgetrn: "Do you wish to forget this user immediatelly?",
  yes: "Yes",
  no: "No",
  newUser: "New user...",
  selUser: "User",
}

const cs : typeof en = {
  name: "Jméno",
  password: "Heslo",
  login: "Přihlásit",
  title: "Power Grid Monitor",

  verifyAccount: "Ověření účtu",
  resetPassword: "Zapomenuté heslo",
  deleteAccount: "Smazat účet",
  createAccount: "Vytvořit účet",

  connectionFailed: "Nelze se připojit k serveru",
  loginFailed: "Nesprávné přihlašovací údaje",

  returnToLogin: "Zpět na přihlášení",
  openInBrowser: "Otevřít mimo aplikaci",

  rememberName: "Uložit jméno",
  rememberPassword: "Uložit heslo",
  forgetrn: "Chcete uživatele okamžitě zapomenout?",
  yes: "Ano",
  no: "Ne",
  newUser: "Nový uživatel...",
  selUser: "Uživatel",
}