import { KeysOf } from "../../FunnyTypes";
import { DeviceInfo, OnlineData, RType } from "./NetTypes";
import { useLoader } from "./useLoader";
import { useRef, useEffect, useReducer } from "react";

const limit = 20;

type DataStructure<T extends keyof OnlineData> = {
  onlineData: RType<"Online"> | null,
  seqData: { [k in T] : OnlineData[T][] };
  generation : number,
  times: Date[]
}
export function CreateEmptyData<T extends keyof OnlineData>(keys : readonly T[]) {
  let seqData = {  } as DataStructure<T>["seqData"];
  for (let i = 0; i < keys.length; i++) {
    seqData[keys[i]] = [];
  }
  return { seqData, onlineData: null, times: [], generation: 0 } as DataStructure<T>;
}

function UpdateData<T extends keyof OnlineData>(existing : DataStructure<T>, newData : RType<"Online">, time : Date) {
  let res : typeof existing = {
    onlineData: newData,
    seqData: {...existing.seqData},
    generation: existing.generation + 1,
    times: existing.times
  };
  res.times.push(time);
  if (res.times.length > limit)
    res.times.shift();

  KeysOf(existing.seqData).forEach(k => {
    res.seqData[k].push(newData.Data[k]);
    if (res.seqData[k].length > limit)
      res.seqData[k].shift();
  });
  return res;
}

function Reducer<T extends keyof OnlineData>(
  state : DataStructure<T>,
  arg : { cmd : "update", data : RType<"Online">, time : Date } | { cmd : "delete", keys : readonly T[]}
) {
  switch (arg.cmd) {
    case "delete":
      return CreateEmptyData(arg.keys);
    case "update":
      return UpdateData(state, arg.data, arg.time);
  }
}

export function useSequentialLoader<T extends keyof OnlineData>(activeDevice : DeviceInfo | null, storedValues : readonly T[], refresh : number) {
  const loader = useLoader("Online_Short", null, activeDevice === null, refresh);
  const device = useRef(activeDevice?.Mqtt_uid);
  const [data, updateData] = useReducer(Reducer<T>, storedValues, CreateEmptyData);

  useEffect(() => {
    if (activeDevice?.Mqtt_uid === undefined) {
      return;
    }
    if (device.current !== activeDevice.Mqtt_uid) {
      device.current = activeDevice.Mqtt_uid;
      updateData({ cmd: "delete", keys: storedValues });
    }
  }, [activeDevice]);

  useEffect(() => {
    if (loader.data) {
      updateData({ cmd: "update", data: loader.data as any, time: new Date() });
    }
  }, [loader.data]);

  return data;
}