import { IonButton, IonCard, IonCol, IonItem, IonLabel, IonListHeader, IonToggle } from "@ionic/react";
import { radioOutline } from "ionicons/icons";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Page } from "../../components/Page";
import { DvcPage } from "../../components/navigation/PageList";
import { RouterMap } from "../../components/navigation/RouterMap";
import { useLoc } from "../../functions/Language";
import { RType, SetOptions } from "../../functions/networking/NetTypes";
import { SetUnitConfig } from "../../functions/networking/Networking";
import { useLoader } from "../../functions/networking/useLoader";

const route = RouterMap.ioState;

/*
const sampleData : RType<"IO_State"> = {
  Head: "" as any,
  Data: {
    K1: { Type: "Digital_input", Input_state: "Off" },
    K2: {
      Type: "Pulse_input",
      Units: "kWh",
      Value: 5,
      Weight: 50
    },
    K3: { Type: "Pulse_output" },
    K4: { Type: "Tariff_input" },
    R1: { Type: "Alarm_output", Output_state: "Off" },
    R2: { Type: "Digital_output", Output_state: "Off" },
    R3: { Type: "Digital_output", Output_state: "On" }
  }
}
*/

const order = [
  "R1", "R2", "R3",
  "K1", "K2", "K3", "K4"
] as const;

const Content : FC = () => {
  const loc = useLoc({en, cs});
  const states = order.map(() => useState(false));
  const updateDesired = useRef(true);
  
  const loader = useLoader("IO_State", null, false, undefined, () => { updateDesired.current = true; }, true);
  const loaders = [loader];

  useEffect(() => {
    if (updateDesired.current && loader.data) {
      for (let i = 0; i < order.length; i++) {
        let data = loader.data.Data[order[i]];
        states[i][1](data.Type === "Digital_output" && data.Output_state === "On");
      }
      updateDesired.current = false;
    }
  }, [loader.data]);

  return <Page title={loc.title} loaders={loaders}>
    <div style={{ height: "calc(100% - 10px)", marginBottom: "5px", marginTop: "5px" }}>
    <IonCard style={{maxWidth: "40em", margin: "auto", minHeight: "100%"}}>
      <IonListHeader style={{paddingInlineEnd: "calc(var(--ion-safe-area-left, 0) + 16px)"}}>
        <IonLabel>{loc.header.name}</IonLabel>
        <IonLabel style={{textAlign: "center"}}>{loc.header.currState}</IonLabel>
        <IonLabel style={{textAlign: "end"}}>{loc.header.newState}</IonLabel>
      </IonListHeader>
      {order.map((k, i) => {
        const data =// sampleData.Data[k];
                     loader.data?.Data?.[k];
        let content = <></>;

        switch (data?.Type) {
          case "Digital_output":
            content = <>
              <StateIndicator state={data.Output_state} />
              <span style={{flex: 1}}>
                <span style={{marginLeft: "-1em"}}>
                  <IonToggle checked={states[i][0]} onIonChange={(ev) => { states[i][1](ev.detail.checked) }}>
                    &nbsp;
                  </IonToggle>
                </span>
              </span>
            </>
            break;
          case "Pulse_input": {
            content = <>
              <span style={{flex: 1}}>
                <span>{data.Value * data.Weight}</span> {data.Units}
              </span>
              <span style={{flex: 1}} />
            </>
          break;
          }
          case "Alarm_output":
            content = <>
              <StateIndicator state={data.Output_state} />
              <span style={{flex: 1}} />
            </>
            break;
          case "Digital_input":
            content = <>
              <StateIndicator state={data.Input_state} />
              <span style={{flex: 1}} />
            </>
            break;
          default:
            break;
        }

        return <Fragment key={i}>
          <IonItem>
            <IonLabel style={{flex: "1", marginRight: "1em", marginTop: ".5em", marginBottom: ".5em", position: "relative"}}>
              <h2>{k}</h2>
              <p>{data ? loc.types[data.Type] : "..."}</p></IonLabel>
            {content}
          </IonItem>
        </Fragment>
      })}
      <IonItem lines="none" className="lastchild" style={{ display: order.some(k => loader.data?.Data[k].Type === "Digital_output") ? "block" : "none"}}>
        <IonButton slot="end" onClick={() => {
            let data = {} as SetOptions["IO_State"];
            for (let i = 0; i < order.length; i++) {
              data[`${order[i]}_On`] = states[i][0];
            }
            SetUnitConfig("IO_State", data).then(res => {
              if (res.err) console.error(res.kind, res.msg);
            });
          }}
          style={{width: "100%", height: "3em", marginInline: 0, marginTop: "1em", "--border-radius": "6px"}}
        >{loc.save}</IonButton>
      </IonItem>
    </IonCard>
    </div>
  </Page>
}

const StateIndicator : FC<{
  state: "On" | "Off"
}> = (props) => {
  return <span style={{
    color: props.state === "On" ? "var(--ion-color-secondary)" : "var(--ion-color-medium)",
    flex: 1
  }}>{props.state.toUpperCase()}</span>
}


const en = {
  title: "Inputs / outputs",

  types: {
    Digital_input: "Digital input",
    Digital_output: "Digital output",
    Alarm_output: "Alarm output",
    Pulse_input: "Pulse input",
    Pulse_output: "Pulse output",
    Tariff_input: "Tariff input"
  },

  digState: "State",
  pulseVal: "Value",

  digOld: "Current state:",
  digNew: "Desired state:",

  header: {
    name: "Name",
    currState: "Current state",
    newState: "Desired state",
  },

  save: "Set desired states",
};

const cs : typeof en = {
  title: "Vstupy / výstupy",

  types: {
    Digital_input: "Digitální vstup",
    Digital_output: "Digitální výstup",
    Alarm_output: "Výstup alarmu",
    Pulse_input: "Vstup pulsů",
    Pulse_output: "Výstup pulsů",
    Tariff_input: "Vstup tarifu"
  },

  digState: "Stav",
  pulseVal: "Hodnota",

  digOld: "Aktuální stav:",
  digNew: "Požadovaný stav:",

  header: {
    name: "Název",
    currState: "Aktuální stav",
    newState: "Požadovaný stav",
  },

  save: "Nastavit požadované stavy",
}

export const IOPage = {
  page: Content,
  title: {en, cs},
  icon: radioOutline,
}

export const IOState : DvcPage = {
  page: Content,
  path: route,
  title: {en, cs},
  icon: radioOutline,

  available(dvc) {
    return dvc.Equipment.Analog_inputs_outputs + dvc.Equipment.Relay_outputs > 0;
  },
}