import { IonCol, IonRow } from "@ionic/react";
import React, { FC } from "react";
import { FormatNumber } from "../functions/Formatting";
import { useLoc } from "../functions/Language";
import { TableCollapser } from "./CollapsableTable";

type Harm = [number, number, number][];
const colTitles = ["L1", "L2", "L3"];
const llTitles = ["L1 - L2", "L2 - L3", "L3 - L1"];
const nandata = [NaN, NaN, NaN];

export const HarmonicsTable : FC<{
  data : { now : Harm, avg : Harm, max : Harm },
  openState : [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  units : string,
  ll : boolean,
}> = (props) => {
  const loc = useLoc({en, cs});

  return <TableCollapser headerFields={props.ll ? llTitles : colTitles} title={loc.title} openState={props.openState} margin="50px">
    {props.data.now.map((_, i) => <div key={i} className="bordered">
      <IonRow>
        <IonCol size="3">{i + 1}</IonCol>
        {props.data.now[i].map((v, j) => <IonCol key={j}>{FormatNumber(v)}{props.units}</IonCol>)}
      </IonRow>

      <span>
        <IonRow style={{ color: "var(--table-color-max)" }}>
          <IonCol size="3">{loc.max}</IonCol>
          {(props.data.max[i] ?? nandata).map((v, j) => <IonCol key={j}>{FormatNumber(v)}{props.units}</IonCol>)}
        </IonRow>
        <IonRow style={{ color: "var(--table-color-avg)" }}>
          <IonCol size="3">{loc.avg}</IonCol>
          {(props.data.avg[i] ?? nandata).map((v, j) => <IonCol key={j}>{FormatNumber(v)}{props.units}</IonCol>)}
        </IonRow>
      </span>
    </div>)}
  </TableCollapser>
}

const en = {
  title: "Values",
  avg: "average",
  max: "max"
}

const cs : typeof en = {
  title: "Hodnoty",
  avg: "průměr",
  max: "max"
}