import { createContext, useContext, useLayoutEffect, useState } from "react";

export const languages = ["en", "cs"] as const;

export type LanguageCode = typeof languages[number];
export function IsLanguageCode(code: unknown): code is LanguageCode {
  return languages.some(v => v === code);
}

export const Language = createContext<LanguageCode>("cs");

export type Glob<T> = {[index in LanguageCode] : T}
//type Loc<T extends Glob<any>> = T extends Glob<infer L> ? L : never;

export function useLoc<T>(glob: Glob<T>) {
  const lng = useContext(Language);
  const [loc, setLoc] = useState<T>(glob[lng]);
  useLayoutEffect(() => {
    setLoc(glob[lng] || glob.en);
  }, [lng]);
  return loc;
}