import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { FC, createContext, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import "./theme/global.css";
import './theme/variables.css';

import { LoadSettings, themes } from './components/AppSettings';
import { ConditionalRoute, DeviceCtx } from './components/navigation/ConditionalRoute';
import { DvcPageRoutes } from './components/navigation/PageList';
import { Language, LanguageCode } from './functions/Language';
import { SetBroker, SetDvc, disconnect } from './functions/networking/Networking';
import { RouterMap } from './components/navigation/RouterMap';
import { LoginPage } from './pages/Access/Login';
import { DevicesPage } from './pages/Access/Devices';
import { CreateEmptyData, useSequentialLoader } from './functions/networking/useSequentialLoader';
import { Preferences } from '@capacitor/preferences';
import { DeviceInfo, ShortOnlineData } from './functions/networking/NetTypes';
import { ResetEnergyCache } from './functions/networking/EnergyLoading';

setupIonicReact();
Preferences.configure({ group: "pla33rx" })

export const DEV = false;

export let ReloadSettings = () => {console.warn("Reloading settings too soon");}
export const RefreshContext = createContext(5);
export const SequentialFields //: readonly (keyof ShortOnlineData)[]
= [
  "U_LN",
  "U_LL",
  "Current",
  "P",
  "Q",
  "S",
  "Frequency",
  "CosFi_Mod",
] as const;
export const SeqContext = createContext(CreateEmptyData(SequentialFields));

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [dvc, setDvc] = useState<DeviceInfo | null>(null);
  const [lang, setLang] = useState<LanguageCode>("cs");
  const [, setTheme] = useState<typeof themes[number]>("light");
  const [refresh, setRefresh] = useState(5);

  const seqData = useSequentialLoader(dvc, SequentialFields, refresh);

  useLayoutEffect(() => {
    ReloadSettings = async () => {
      let cfg = await LoadSettings();
      
      setLang(cfg.language);
      setRefresh(cfg.refresh);
      
      setTheme(cfg.theme);
      switch (cfg.theme) {
        case 'dark':
          document.body.classList.add("dark");
          break;
        case 'light':
          document.body.classList.remove("dark");
      }
      SetBroker(cfg.mqttBroker);
    }

    ReloadSettings();
  }, []);

  useEffect(() => {
    if (!loggedIn && !DEV) {
      disconnect();
      setDvc(null);
    }
  }, [loggedIn]);

  useLayoutEffect(() => {
    SetDvc(dvc?.Mqtt_uid ?? null);
    if (DEV && dvc) {
      localStorage.setItem("DEV_DEVICE_DETAIL", JSON.stringify(dvc));
    }
    ResetEnergyCache();
  }, [dvc]);

  return <IonApp>
    <Language.Provider value={lang}>
    <RefreshContext.Provider value={refresh}>
    <DeviceCtx.Provider value={dvc ?? (DEV ? JSON.parse(localStorage.getItem("DEV_DEVICE_DETAIL") ?? "null") : null)}>
    <SeqContext.Provider value={seqData}>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={RouterMap.login}>
          <LoginPage setLoggedIn={setLoggedIn} />
        </Route>
        <ConditionalRoute path={RouterMap.devices} fallback={RouterMap.login} cond={loggedIn || DEV}>
          <DevicesPage setDevice={setDvc} />
        </ConditionalRoute>

        {DvcPageRoutes}

        <Route exact path={RouterMap.logout}>
          <Logout setLoggedIn={setLoggedIn} />
        </Route>

        <Route>
          <Redirect to={RouterMap.login} />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
    </SeqContext.Provider>
    </DeviceCtx.Provider>
    </RefreshContext.Provider>
    </Language.Provider>
  </IonApp>
}

const Logout : FC<{
  setLoggedIn: (val: boolean) => void
}> = (props) => {
  useLayoutEffect(() => {
    props.setLoggedIn(false);
  }, []);

  return <Redirect to={RouterMap.login} />
}

export default App;
