import { OnlineData } from "./functions/networking/NetTypes";

const enFields //: { [k in keyof OnlineData]? : string }
= {
  CosFi: "cos φ",
  CosFi_Mod: "cos φ",
  Current: "I",
  Frequency: "f",
  KFactor: "K factor",
  Over_Deviation_LL: "Over deviation U_LL",
  Over_Deviation_LN: "Over deviation U_LN",
  P: "P",
  Pf: "Pf",
  Pf_Mod: "Pf",
  Q: "Q",
  S: "S",
  Rotation: "Rotation",
  Tdd: "TDD",
  Thd_Current: "THD I",
  Thd_U_LL: "THD U_LL",
  Thd_U_LN: "THD U_LN",
  Temperature: "Temperature",
  U_LL: "U L-L",
  U_LN: "U L-N",
  Unbalance_I_I0: "Unbalance I0",
  Unbalance_I_I2: "Unbalance I2",
  Unbalance_LN_U0: "Unbalance U0",
  Unbalance_LN_U2: "Unbalance U2",
  Under_Deviation_LL: "Under deviation U_LL",
  Under_Deviation_LN: "Under deviation U_LN" 
}

const csFields : typeof enFields
= {
  CosFi: "cos φ",
  CosFi_Mod: "cos φ",
  Current: "I",
  Frequency: "f",
  KFactor: "K faktor",
  Over_Deviation_LL: "Over deviation U_LL",
  Over_Deviation_LN: "Over deviation U_LN",
  P: "P",
  Pf: "Pf",
  Pf_Mod: "Pf",
  Q: "Q",
  S: "S",
  Rotation: "Rotace",
  Tdd: "TDD",
  Thd_Current: "THD I",
  Thd_U_LL: "THD U_LL",
  Thd_U_LN: "THD U_LN",
  Temperature: "Teplota",
  U_LL: "U L-L",
  U_LN: "U L-N",
  Unbalance_I_I0: "Unbalance I0",
  Unbalance_I_I2: "Unbalance I2",
  Unbalance_LN_U0: "Unbalance U0",
  Unbalance_LN_U2: "Unbalance U2",
  Under_Deviation_LL: "Under deviation U_LL",
  Under_Deviation_LN: "Under deviation U_LN" 
}

export const fieldNames = { en: enFields, cs: csFields };

const enFieldsShort : typeof enFields
= {
  CosFi: "cos φ",
  CosFi_Mod: "cos φ",
  Current: "I",
  Frequency: "f",
  KFactor: "K factor",
  Over_Deviation_LL: "Over deviation",
  Over_Deviation_LN: "Over deviation",
  P: "P",
  Pf: "Pf",
  Pf_Mod: "Pf",
  Q: "Q",
  S: "S",
  Rotation: "Rotation",
  Tdd: "TDD",
  Thd_Current: "THD",
  Thd_U_LL: "THD",
  Thd_U_LN: "THD",
  Temperature: "Temperature",
  U_LL: "U L-L",
  U_LN: "U L-N",
  Unbalance_I_I0: "Unbalance I0",
  Unbalance_I_I2: "Unbalance I2",
  Unbalance_LN_U0: "Unbalance U0",
  Unbalance_LN_U2: "Unbalance U2",
  Under_Deviation_LL: "Under deviation",
  Under_Deviation_LN: "Under deviation" 
}

const csFieldsShort : typeof enFieldsShort
= {
  CosFi: "cos φ",
  CosFi_Mod: "cos φ",
  Current: "I",
  Frequency: "f",
  KFactor: "K faktor",
  Over_Deviation_LL: "Over deviation",
  Over_Deviation_LN: "Over deviation",
  P: "P",
  Pf: "Pf",
  Pf_Mod: "Pf",
  Q: "Q",
  S: "S",
  Rotation: "Rotace",
  Tdd: "TDD",
  Thd_Current: "THD",
  Thd_U_LL: "THD",
  Thd_U_LN: "THD",
  Temperature: "Teplota",
  U_LL: "U L-L",
  U_LN: "U L-N",
  Unbalance_I_I0: "Unbalance I0",
  Unbalance_I_I2: "Unbalance I2",
  Unbalance_LN_U0: "Unbalance U0",
  Unbalance_LN_U2: "Unbalance U2",
  Under_Deviation_LL: "Under deviation",
  Under_Deviation_LN: "Under deviation" 
}

export const shortNames = { en: enFieldsShort, cs: csFieldsShort };

export const units = {
  CosFi: "",
  CosFi_Mod: "",
  Current: "A",
  Frequency: "Hz",
  KFactor: "",
  Over_Deviation_LL: "V",
  Over_Deviation_LN: "V",
  P: "W",
  Pf: "",
  Pf_Mod: "",
  Q: "var",
  S: "VA",
  Rotation: "",
  Tdd: "%",
  Thd_Current: "%",
  Thd_U_LL: "%",
  Thd_U_LN: "%",
  Temperature: "°C",
  U_LL: "V",
  U_LN: "V",
  Unbalance_I_I0: "%",
  Unbalance_I_I2: "%",
  Unbalance_LN_U0: "%",
  Unbalance_LN_U2: "%",
  Under_Deviation_LL: "V",
  Under_Deviation_LN: "V" 
}

export const phaseLabels = {
  en: {
    LN: ["All phases", "L1", "L2", "L3"],
    LL: ["", "L1 - L2", "L2 - L3", "L3 - L1"]
  },
  cs: {
    LN: ["Všechny fáze", "L1", "L2", "L3"],
    LL: ["", "L1 - L2", "L2 - L3", "L3 - L1"]
  }
};

let assert1 : keyof typeof enFields extends keyof OnlineData ? true : false = true;
let assert2 : keyof typeof units extends keyof typeof enFields ? true : false = true;
let assert3 : keyof typeof enFields extends keyof typeof units ? true : false = true;