import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { DropdownSelectItem } from "../../components/DropdownSelect";
import { HarmonicsChart } from "../../components/charts/HarmonicsChart";
import { Page } from "../../components/Page";
import { DvcPage } from "../../components/navigation/PageList";
import { useLoc } from "../../functions/Language";
import { useLoader } from "../../functions/networking/useLoader";
import { RouterMap } from "../../components/navigation/RouterMap";
import { HarmonicsTable } from "../../components/HarmonicsTable";
import harmIcon from "../../img/harmonics.svg";
import { HarmSimple } from "../../functions/networking/NetTypes";
import { OptionsButton } from "../../components/OptionsButton";
import { optionsOutline } from "ionicons/icons";

const route = RouterMap.harmonics;
const Link : FC = () => {
  const loc = useLoc({en, cs});
  return <IonItem routerLink={route}>
    <IonLabel>{loc.title}</IonLabel>
  </IonItem>
}

const requests : HarmSimple[] = [
  "Harm_U_LN",
  "Harm_U_LL",
  "Harm_Current",
  "Harm_U_LN_Percent",
  "Harm_U_LL_Percent",
  "Harm_Current_Percent"
];

function ReqName(mode : modes, percent : number) : HarmSimple {
  if (mode > 2 || percent > 1) throw "Invalid arguents";
  return requests[mode + 3 * percent];
}

enum modes {
  ULN = 0,
  ULL = 1,
  I = 2,
}

const Content : FC = () => {
  const loc = useLoc({en, cs});
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setPercent(1);
  }, []);
  const [mode, setMode] = useState(modes.ULN);
  const [mm, setMm] = useState(false);
  const tableState = useState(false);
  const [tableOpen,] = tableState;
  const phaseNames = useMemo(
    () => mode === modes.ULL ? [loc.all, "L1-L2", "L2-L3", "L3-L1"] : [loc.all, "L1", "L2", "L3"]
  , [mode]);
  const loader = useLoader(ReqName(mode, percent), { Data : [] });
  const maxLoader = useLoader(`${ReqName(mode, percent)}_Max`, { Data : [] }, !mm && !tableOpen);
  const avgLoader = useLoader(`${ReqName(mode, percent)}_Avg`, { Data : [] }, !tableOpen);

  const loaders = [loader, maxLoader, avgLoader];

  const Options = <OptionsButton icon={optionsOutline} title="">
    {Close => <>
      <DropdownSelectItem
        label={loc.mode}
        selected={mode} setSelected={setMode} options={[
          <>U<sub>LN</sub></>,
          <>U<sub>LL</sub></>,
          "I"]}
      />

      <DropdownSelectItem
        label={loc.show}
        selected={percent} setSelected={setPercent} options={loc.showOptions}
      />

      <IonItem>
        <IonCheckbox checked={mm} onIonChange={e => setMm(e.detail.checked)}>{loc.minMax}</IonCheckbox>
      </IonItem>
    </>}
  </OptionsButton>

  return <Page title={loc.title} loaders={loaders} additionalButtons={Options}>
    <HarmonicsChart
      data={loader.data.Data}
      phaseNames={phaseNames}
      units={percent ? "%" : mode === modes.I ? "A" : "V"}
      minMax={mm ? { min: maxLoader.data.Data, max: maxLoader.data.Data } : undefined}
      skipFirst={percent === 1}
    />

    <HarmonicsTable
      data={{now: loader.data.Data, avg: avgLoader.data.Data, max: maxLoader.data.Data}}
      openState={tableState}
      units={percent ? "%" : mode === modes.I ? "A" : "V"}
      ll={mode===modes.ULL}
    />
  </Page>
}

const en = {
  title: "Harmonics",
  show: "Display",
  showOptions: ["Values", "Percents"] as [ReactNode, ReactNode],
  mode: "Mode",
  all: "All",
  minMax: "Show maxima",

  options: "Options",
};

const cs : typeof en = {
  title: "Harmonické",
  show: "Zobrazení",
  showOptions: ["Hodnoty", "Procenta"],
  mode: "Veličina",
  all: "Všechny",
  minMax: "Zobrazovat maxima",

  options: "Možnosti",
}

export const PHarmonics : DvcPage = {
  page: Content,
  path: route,
  title: {en, cs},
  icon: harmIcon
}