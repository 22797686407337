import React, { forwardRef } from "react";
import { BarWithErrorBarsController } from "chartjs-chart-error-bars";
import { Chart as ChartJS } from "chart.js";
import { ChartJSOrUndefined, ChartProps, TypedChartComponent } from "react-chartjs-2/dist/types";
import { Chart } from "react-chartjs-2";

const id = BarWithErrorBarsController.id;
type id = typeof id;
ChartJS.register(BarWithErrorBarsController);

export const MinMaxChart = forwardRef<ChartJSOrUndefined<id>, Omit<ChartProps<id>, 'type'>>(
  function MMChart(props, ref) { return <Chart {...props} ref={ref} type={id} /> }
) as TypedChartComponent<id>;