import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { reloadOutline } from "ionicons/icons";
import React, { FC, useEffect, useReducer, useRef, useState } from "react";
import { AppSettings } from "../../components/AppSettings";
import { LogoutOnlyNavBar } from "../../components/navigation/NavBar";
import { useLoc } from "../../functions/Language";
import { AskForPresenceImmediatelly, KeepAskingForPresence, SetDvc } from "../../functions/networking/Networking";
import { Overview } from "../Overview";
import { DeviceInfo } from "../../functions/networking/NetTypes";

export const DevicesPage : FC<{
  setDevice: (dvc: DeviceInfo | null) => void
}> = (props) => {
  const devices = useRef<DeviceInfo[]>([]);
  const [, redraw] = useReducer((v : number) => v + 1, 0);
  const loc = useLoc({en, cs});
  const canceler = useRef({ canceled : true });

  useEffect(() => {
    devices.current = [];
    canceler.current.canceled = false;
    props.setDevice(null);
    SetDvc(null);

    KeepAskingForPresence((dvc) => {
      if (dvc && devices.current.every(d => d.Mqtt_uid !== dvc.Mqtt_uid)) {
        devices.current.push(dvc);
        devices.current.sort((a, b) => a.Mqtt_uid < b.Mqtt_uid ? -1 : 1);
        redraw();
      }
    });
    AskForPresenceImmediatelly();

    return () => {
      canceler.current.canceled = true;
    }
  }, []);

  return <IonPage>
    <IonHeader>
      <IonToolbar color="tertiary">
        <IonTitle>{loc.title}</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => {
            devices.current = [];
            redraw();
            AskForPresenceImmediatelly();
          }}>
            <IonIcon icon={reloadOutline} />
          </IonButton>
          <AppSettings />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <IonList>
        {devices.current.map((dvc, i) => <IonItem
            key={i}
            onClick={() => {
              props.setDevice(dvc);
            }}
            routerLink={Overview.path}
            routerDirection="root"
          >
          <IonLabel>
            <h2>{dvc.Description}</h2>
            <h3>{dvc.Name}</h3>
          </IonLabel>
        </IonItem>)}
      </IonList>
      {devices.current.length === 0 && 
        <IonItem>
          <IonSpinner />
          <IonLabel>&nbsp;{loc.loading}</IonLabel>
        </IonItem>}
    </IonContent>

    <LogoutOnlyNavBar />
  </IonPage>
}

const en = {
  logout: "Log out",
  loading: "Searching for devices...",
  title: "Device selection",
  no_devices: "No devices found."
}

const cs : typeof en = {
  logout: "Odhlásit",
  loading: "Hledání přístrojů...",
  title: "Výběr zařízení",
  no_devices: "Nebyl nalezen žádný přístoj."
}