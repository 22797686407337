import { IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonModal, IonRow } from "@ionic/react";
import React, { FC, useState } from "react";
import { Overview } from "../../pages/Overview";
import { hardwareChip, logOut, trailSign } from "ionicons/icons";
import { useLoc } from "../../functions/Language";
import { RouterMap } from "./RouterMap";
import { DvcPageList, PageList } from "./PageList";
import "./NavBar.css";

export const NavBar : FC = () => {
  const loc = useLoc({en, cs});

  return <IonFooter>
    <IonRow>
      <IonItem routerLink={RouterMap.overview} className="navbutton" lines="none" routerDirection="root" detail={false}>
        <Overview.Button />
      </IonItem>
      <QuickNav />
      <IonItem routerLink={RouterMap.devices} className="navbutton" lines="none" detail={false} routerDirection="root">
        <NavButtonContent icon={hardwareChip} color="warning" text={loc.devices} />
      </IonItem>
      <IonItem routerLink={RouterMap.logout} className="navbutton" lines="none" detail={false} routerDirection="root">
        <NavButtonContent icon={logOut} text={loc.logout}  color="danger" />
      </IonItem>
    </IonRow>
  </IonFooter>
}

const en = {
  quicknav: "Quick navigation",
  devices: "Device selection",
  logout: "Log out"
}

const cs : typeof en = {
  quicknav: "Rychlá navigace",
  devices: "Výběr zařízení",
  logout: "Odhlásit"
}

export const NavButtonContent : FC<{
  icon : string,
  text : string,
  color? : typeof IonLabel extends FC<{color: infer P}> ? P : never
}> = (props) => <IonLabel>
  <h1><IonIcon icon={props.icon} color={props.color} aria-hidden /></h1>
  <h3>{props.text}</h3>
</IonLabel>

const QuickNav : FC = () => {
  const label = useLoc({en: "Quick navigation", cs: "Rychlá navigace"})
  const [isOpen, setOpen] = useState(false);

  return <IonItem button className="navbutton" lines="none" onClick={() => setOpen(v => !v)} detail={false}>
    <NavButtonContent icon={trailSign} text={label} />

    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => setOpen(false)}
      breakpoints={[0, .6, 1]}
      initialBreakpoint={.6}
      backdropBreakpoint={0}
    >
      <IonContent>
        <PageList pages={DvcPageList} routerDirection="root" />
      </IonContent>
    </IonModal>
  </IonItem>
}

export const LogoutOnlyNavBar : FC = () => {
  const loc = useLoc({en, cs});

  return <IonFooter>
    <IonRow>
      <span style={{flex: 3}} />
      <IonItem routerLink={RouterMap.logout} className="navbutton" lines="none" routerDirection="root" detail={false}>
        <NavButtonContent icon={logOut} text={loc.logout} color="danger" />
      </IonItem>
    </IonRow>
  </IonFooter>
}