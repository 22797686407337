import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { FC, ReactNode, useState } from "react";
import { useLoc } from "../functions/Language";

export const OptionsButton : FC<{
  icon : string,
  title : string,
  children : (Close : () => void) => ReactNode
}> = (props) => {
  const loc = useLoc({ en, cs });
  const [isOpen, setOpen] = useState(false);

  function Close() {
    setOpen(false);
  }

  return <>
    <IonButton onClick={() => setOpen(true)}>
      <IonIcon icon={props.icon} />
    </IonButton>
    <IonModal isOpen={isOpen} onWillDismiss={Close}>
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={Close}>{loc.close}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {props.children(Close)}
      </IonContent>
    </IonModal>
  </>
}

const en = {
  close: "Close",
}

const cs : typeof en = {
  close: "Zavřít",
}