import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { colorPaletteOutline, informationCircleOutline, languageOutline, refreshOutline, repeatOutline, settingsOutline, wifiOutline } from "ionicons/icons";
import React, { FC, useEffect, useState } from "react";
import { ReloadSettings } from "../App";
import cfg from "../config.json";
import { IsLanguageCode, LanguageCode, languages, useLoc } from "../functions/Language";
import { DropdownSelectItem } from "./DropdownSelect";
import { Preferences } from "@capacitor/preferences";

const defaultMqttBroker = "mqtts://mqtt.bmr.cz:5281/mqtt";

export type PrefKey = "lang" | "theme" | "refresh" | "mqtt-broker" | "users" | "selUser";
export const themes = ["light", "dark"] as const;
const refreshOptions = [1, 5, 30, -1];

export async function LoadSettings() {
  let [
    { value: lng },
    { value: theme },
    { value: refresh_ },
    { value: mqttBroker }
  ] = await Promise.all([
    Preferences.get({ key: "lang" as PrefKey }),
    Preferences.get({ key: "theme" as PrefKey }),
    Preferences.get({ key: "refresh" as PrefKey }),
    Preferences.get({ key: "mqtt-broker" as PrefKey })
  ]);
  let refresh = Number.parseInt(refresh_ ?? "");

  return {
    language: IsLanguageCode(lng) ? lng : "cs",
    theme: (themes.some(v => v === theme) ? theme : "light") as typeof themes[number],
    refresh: isNaN(refresh) ? 5 : refresh,
    mqttBroker: mqttBroker?.length ? mqttBroker : defaultMqttBroker
  }
}

export const AppSettings : FC<{
  loggedOut ?: boolean
}> = (props) => {
  const loc = useLoc({en, cs});
  const [isOpen, setOpen] = useState(false);
  const [langid, setLangid] = useState(1);
  const [, setLang] = useState<LanguageCode>("cs");
  const [theme, setTheme] = useState(0);
  const [refreshId, setRefreshId] = useState(0);
  const [mqttBroker, setMqttBroker] = useState(defaultMqttBroker);

  useEffect(() => {
    (async () => {
      let conf = await LoadSettings();

      setLang(conf.language);
      let langid = languages.indexOf(conf.language);
      if (langid < 0)
        langid = 0;
      setLangid(langid)
  
      let rid = refreshOptions.indexOf(conf.refresh);
      if (rid < 0)
        rid = 0;
      setRefreshId(rid);
      
      let themeid = themes.indexOf(conf.theme);
      if (themeid < 0)
        themeid = 0;
      setTheme(themeid);
      switch (conf.theme) {
        case 'dark':
          document.body.classList.add("dark");
          break;
        case 'light':
          document.body.classList.remove("dark");
      }

      setMqttBroker(conf.mqttBroker);
    })();
  }, []);

  useEffect(() => {
    Preferences.set({ key: "mqtt-broker" as PrefKey, value: mqttBroker });
    ReloadSettings();
  }, [mqttBroker]);

  return <>
    <IonButton onClick={() => setOpen(true)}>
      <IonIcon icon={settingsOutline} />
    </IonButton>
    <IonModal isOpen={isOpen} onWillDismiss={() => setOpen(false)}>
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonTitle>{loc.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setOpen(false)}>{loc.close}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <DropdownSelectItem
          label="Language"
          options={["English", "Čeština"]}
          selected={langid}
          setSelected={id => {
            setLangid(id);
            Preferences.set({ key: "lang" as PrefKey, value: languages[id] });
            ReloadSettings();
          }}
          icon={languageOutline}
        />

        <DropdownSelectItem
          label={loc.theme}
          options={themes.map(v => loc[`theme_${v}`])}
          selected={theme}
          setSelected={id => {
            setTheme(id);
            Preferences.set({ key: "theme" as PrefKey, value: themes[id] });
            ReloadSettings();
          }}
          icon={colorPaletteOutline}
        />

        <DropdownSelectItem
          label={loc.refresh}
          options={refreshOptions.map(v => v < 0 ? loc.refreshnt : `${v} s`)}
          selected={refreshId}
          setSelected={id => {
            setRefreshId(id);
            Preferences.set({ key: "refresh" as PrefKey, value: refreshOptions[id].toString() });
            ReloadSettings();
          }}
          icon={refreshOutline}
        />

        <IonItem>
          <IonIcon icon={repeatOutline} />&nbsp;
          <IonInput
            disabled={!props.loggedOut}
            label={loc.mqttBroker}
            labelPlacement="stacked"
            placeholder={defaultMqttBroker}
            value={mqttBroker}
            onIonInput={ev => {
              setMqttBroker(ev.detail.value ?? defaultMqttBroker);
            }}
          />
        </IonItem>

        <IonItem>
          <IonIcon icon={informationCircleOutline} />
          <IonLabel>&nbsp;{loc.version}</IonLabel>
          <IonText color="medium">{cfg.version}</IonText>
        </IonItem>
      </IonContent>
    </IonModal>
  </>
}

const en = {
  close: "Close",
  title: "Application settings",
  theme: "Theme",
  theme_light: "Light",
  theme_dark: "Dark",
  version: "Application version",
  refresh: "Refreshing period",
  refreshnt: "Do not refresh",
  mqttBroker: "MQTT broker"
}

const cs : typeof en = {
  close: "Zavřít",
  title: "Nastavení aplikace",
  theme: "Motiv",
  theme_light: "Světlý",
  theme_dark: "Tmavý",
  version: "Verze aplikace",
  refresh: "Interval načítání",
  refreshnt: "Nenačítat",
  mqttBroker: "MQTT broker"
}