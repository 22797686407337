import { IonCard, IonCardHeader, IonCardTitle, IonCol, IonIcon, IonRow } from "@ionic/react"
import { chevronForwardOutline } from "ionicons/icons"
import React, { FC, PropsWithChildren } from "react"
import { useLoc } from "../functions/Language"
import "./OverviewCard.css"

export const OverviewCard : FC<PropsWithChildren<{
  title: string,
  href: string,
}>> = (props) => {
  const link = useLoc({en: "See more", cs: "Zobrazit více"})

  return <IonCard routerLink={props.href} style={{marginBottom: "6px"}}>
    <IonCardHeader>
      <IonCardTitle>
        {props.title}
        
      </IonCardTitle>
    </IonCardHeader>
    {/* <IonCardContent> ?*/}
    {props.children}
    <IonRow>
      <IonCol style={{color: "var(--ion-color-primary)", display: "flex", flexDirection: "row-reverse", padding: "10px"}}>
        <span>
          {link}
          <IonIcon icon={chevronForwardOutline} />
        </span>
      </IonCol>
    </IonRow>
  </IonCard>
}