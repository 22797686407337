import { IonItem, IonLabel, IonRow } from "@ionic/react";
import React, { FC, useMemo } from "react";
import { Page } from "../../components/Page";
import { useLoc } from "../../functions/Language";
import { useLoader } from "../../functions/networking/useLoader";
import { CollapsableTable } from "../../components/CollapsableTable";
import { DvcPage } from "../../components/navigation/PageList";
import { RouterMap } from "../../components/navigation/RouterMap";
import { speedometerOutline } from "ionicons/icons";
import { shortNames } from "../../GeneralTranslations";

const route = RouterMap.online;
const Link : FC = () => {
  const loc = useLoc({en, cs});
  return <IonItem routerLink={route}>
    <IonLabel>{loc.title}</IonLabel>
  </IonItem>
}

const Content : FC = () => {
  const loc = useLoc({en, cs});
  const rloc = useLoc(shortNames);
  const loader = useLoader("Online", null);
  const minLoader = useLoader("Online_Min", null);
  const avgLoader = useLoader("Online_Avg", null);
  const maxLoader = useLoader("Online_Max", null);

  const loaders = [loader, minLoader, avgLoader, maxLoader]

  const mamData = useMemo(() => 
    minLoader.data !== null && avgLoader.data !== null && maxLoader.data !== null
    ? { min: minLoader.data.Data, avg: avgLoader.data.Data, max: maxLoader.data.Data }
    : undefined,
    [ minLoader.data, avgLoader.data, maxLoader.data]
  );

  return <Page
    title={loc.title}
    loaders={loaders}
  >
    {loader.data === null ? <></> : <IonRow style={{marginInline: ".25%"}}>
      <span className="group">
      <CollapsableTable
        title={loc.U_LN}
        data={loader.data.Data}
        rows={[
          { d: "U_LN" },
          { d: "Thd_U_LN" },
          { t: "Unbalance_LN_U0" },
          { t: "Unbalance_LN_U2" },
          { d: "Under_Deviation_LN" },
          { d: "Over_Deviation_LN"},
          { t: "Frequency" },
        ]}
        mam={mamData}
        labels={rloc}
      />
      <CollapsableTable
        title={loc.U_LL}
        data={loader.data.Data}
        rows={[
          { d: "U_LL" },
          { d: "Thd_U_LL" },
          { d: "Over_Deviation_LL" },
          { d: "Under_Deviation_LL" },
        ]}
        LL
        mam={mamData}
        labels={rloc}
      />
      </span>
      <span className="group">
      <CollapsableTable
        title={loc.I}
        data={loader.data.Data}
        rows={[
          { d: "Current", t: "Current_Zero" },
          { d: "Thd_Current" },
          { t: "Unbalance_I_I0" },
          { t: "Unbalance_I_I2" },
          { d: "Tdd" },
          { d: "KFactor" },
        ]}
        mam={mamData}
        labels={rloc}
      />
      <CollapsableTable
        title={loc.P}
        data={loader.data.Data}
        rows={[
          { d: "CosFi_Mod", t: "CosFi_3F_Mod" },
          { d: "Pf_Mod", t: "Pf_Mod" },
          { d: "P", t: "P_3F" },
          { d: "Q", t: "Q_3F" },
          { d: "S", t: "S_3F" }
        ]}
        mam={mamData}
        labels={rloc}
      />
      <CollapsableTable
        title={loc.misc}
        data={loader.data.Data}
        rows={[{ t: "Temperature" }]}
        mam={mamData}
        labels={rloc}
      />
      </span>
    </IonRow>}
  </Page>
}

const en = {
  title: "Tables",
  minMax: "Show minima, averages and maxima",

  U_LN: "Phase Voltage",
  U_LL: "Line Voltage",
  I: "Current",
  P: "Power",
  misc: "Other"
};

const cs : typeof en = {
  title: "Tabulky",
  minMax: "Zobrazit minima, průměry a maxima",

  U_LN: "Fázové napětí",
  U_LL: "Sdružené napětí",
  I: "Proud",
  P: "Výkon",
  misc: "Jiné"
}

export const Online = {
  Link: Link
}

export const POnline : DvcPage = {
  page: Content,
  path: route,
  title: { en, cs },
  icon: speedometerOutline
}