import React, { IonItem, IonLabel, IonList, IonRange } from "@ionic/react";
import { CategoryScale, ChartData, ChartDataset, ChartOptions, Tooltip, TooltipItem } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import { BarWithErrorBar } from "chartjs-chart-error-bars";
import zoomPlugin from "chartjs-plugin-zoom";
import { FC, useEffect, useLayoutEffect, useMemo, useReducer, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useLoc } from "../../functions/Language";
import { MinMaxChart } from "./MinMaxChart";
import { FormatNumber } from "../../functions/Formatting";

ChartJS.register(CategoryScale, zoomPlugin, BarWithErrorBar);
// @ts-ignore
(Tooltip.positioners).bottom = function(items, epos) {
  // @ts-ignore
  const pos = Tooltip.positioners.average(items, epos);

  // Happens when nothing is found
  if (pos === false) {
    return false;
  }

  // @ts-ignore
  const chart = this.chart;

  return {
    x: pos.x,
    y: chart.chartArea.bottom,
    yAlign: 'bottom',
  };
};

type MinMax = { min: [number, number, number][], max: [number, number, number][] };
const colors = ["#ff0000", "#00ee00", "#3f3fff"];
const maxColors = ["#00cfff", "#ff11ff", "#c0c000"];

function DataSet(data: [number, number, number][], i: number, phaseNames: string[]) {
  return {
    label: phaseNames[i],
    backgroundColor: colors[i - 1],
    data: data.map(v => v[i - 1])
  }
}

function MMDataSet(data: [number, number, number][], mm: MinMax, i: number, phaseNames: string[]) : ChartDataset<"barWithErrorBars"> {
  return {
    label: phaseNames[i],
    backgroundColor: colors[i - 1],
    data: mm.max.map((_, j) => ({
      y: data[j][i - 1],
      yMin: mm.max[j][i - 1],
      yMax: mm.max[j][i - 1]
    })),
    errorBarWhiskerRatio: 1,
    errorBarWhiskerColor: maxColors[i - 1]
  }
}

export const HarmonicsChart : FC<{
  data: [number, number, number][],
  phaseNames: string[],
  units: string,
  minMax?: MinMax,
  forceRedraw?: unknown,
  labels?: string[]
  skipFirst?: boolean,
}> = (props) => {
  const loc = useLoc({en: {range: "Plot range"}, cs: {range: "Rozsah grafu"}});
  
  const chart = useRef<ChartJSOrUndefined<"bar"> & ChartJSOrUndefined<"barWithErrorBars">>()
  const data = useMemo<ChartData<"bar">>(() => ({
    datasets: props.minMax !== undefined ? [] : [
      DataSet(props.data, 1, props.phaseNames),
      DataSet(props.data, 2, props.phaseNames),
      DataSet(props.data, 3, props.phaseNames),
    ],
    labels: props.labels ?? props.data.map((_, i) => i + 1),
  }), [props.data, props.minMax, props.forceRedraw, props.labels]);
  const mmdata = useMemo<ChartData<"barWithErrorBars">>(() => ({
    datasets: props.minMax === undefined ? [] : [
      MMDataSet(props.data, props.minMax, 1, props.phaseNames),
      MMDataSet(props.data, props.minMax, 2, props.phaseNames),
      MMDataSet(props.data, props.minMax, 3, props.phaseNames),
    ],
    labels: props.labels ?? props.data.map((_, i) => i + 1),
  }), [props.data, props.minMax, props.forceRedraw, props.labels]);
  const range = useRef({lower: props.skipFirst ? 2 : 1, upper: 40});
  const preview = useRef({ lower: props.skipFirst ? 2 : 1, upper: 40 });

  const [,redraw] = useReducer(v => v + 1, 0);
  useEffect(() => {
    range.current = { lower: props.skipFirst ? 2 : 1, upper: 40 };
    preview.current = range.current;
    redraw();
  }, [props.skipFirst]);

  const options = useRef<ChartOptions<"bar"> & ChartOptions<"barWithErrorBars">>({
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          callback (i) {
            let labels = this.getLabels();
            if (typeof i === "string")
              return i;
            return i % 5 === 4 ? labels[i] : undefined
          }
        },
        grid: {
          offset: false,
        }
      },
      y: {
        ticks: {
          callback(v) {
            if (typeof v === "string") return v;
            else return FormatNumber(v) + props.units
          }
        }
      }
    },
    plugins: {
      legend: {
        //display: false
      },
      tooltip: {
        intersect: false,
        axis: "x",
        // @ts-ignore
        position: "bottom",
        mode: "index",
        callbacks: {
          label(ctx : TooltipItem<"bar"> & TooltipItem<"barWithErrorBars">) {
            let label = ctx.dataset.label;
            if (label)
              label += ": "
            if (ctx.parsed.y !== null) {
              label += FormatNumber(ctx.parsed.y);
            }
            if (ctx.parsed.yMin !== undefined && ctx.parsed.yMax !== undefined) {
              label += `(max: ${FormatNumber(ctx.parsed.yMax as number, true)})`;
            }
            return label;
          }
        }
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgba(250, 100, 0, 0.3)",
          },
          mode: "x",
          onZoomComplete: (ctx) => {
            let ax = ctx.chart.scales["x"];
            if (range.current.lower !== ax.min || range.current.upper !== ax.max) {
              range.current = { lower: ax.min, upper: ax.max };
              preview.current = range.current;
              redraw();
              setTimeout(() => {
                chart.current?.zoomScale("x", { min: range.current.lower, max: range.current.upper });
              }, 0);
            }
          }
        }
      }
    }
  });

  useEffect(() => {
    options.current.scales!.y!.ticks!.callback = (v) => {
      if (typeof v === "string") return v;
      else return FormatNumber(v) + props.units
    };
    chart.current!.options = options.current;
  }, [props.units]);

  useEffect(() => {
    chart.current?.zoomScale("x", {min: range.current.lower, max: range.current.upper });
  }, [props.minMax, props.skipFirst]);

  const previewPlugin = useRef({
    id: "zoomPreview",
    beforeDraw(chart : ChartJS) {
      if (preview.current.lower < range.current.lower
        || preview.current.upper > range.current.upper)
        return;

      let yAxis = chart.scales.y;
      let xAxis = chart.scales.x;

      let s = xAxis.width / (range.current.upper - range.current.lower + 1);

      let x1 = xAxis.left + (preview.current.lower - range.current.lower) * s;
      let x2 = xAxis.left + (preview.current.upper - range.current.lower + 1) * s;
      let ctx = chart.ctx;

      ctx.save();

      ctx.fillStyle = "#cccccc";
      ctx.fillRect(xAxis.left, yAxis.top, x1 - xAxis.left, yAxis.height);
      ctx.fillRect(x2, yAxis.top, xAxis.right - x2, yAxis.height);

      ctx.restore();
    }
  });

  return <IonList style={{ height: "100%", display: "flex", flexDirection: "column" }}>
    <IonItem lines="none" style={{ flex: "auto", "--min-height": "100%" }}>
      <div style={{ width: "100%", marginInline: "auto", height: "100%" }}>
        {props.minMax === undefined
          ? <Bar
            style={{ position: "absolute", top: "0" }}
            ref={chart}
            data={data}
            onDoubleClick={() => {
              chart.current?.resetZoom();
              range.current = { lower: props.skipFirst ? 2 : 1, upper: 40 };
              preview.current = range.current;
              redraw();
            }}
            options={options.current}
            plugins={[previewPlugin.current]}
          />
          : <MinMaxChart
            style={{ position: "absolute", top: "0" }}
            ref={chart}
            data={mmdata}
            onDoubleClick={() => {
              chart.current?.resetZoom();
              range.current = { lower: props.skipFirst ? 2 : 1, upper: 40 };
              preview.current = range.current;
              redraw();
            }}
            options={options.current}
            plugins={[previewPlugin.current]}
          />
        }</div>
    </IonItem>
   
    <IonItem>
      <IonRange
        label={loc.range}
        style={{
          paddingLeft: 15,
          paddingRight: 15,
        }}
        dualKnobs
        min={props.skipFirst ? 2 : 1}
        max={props.data.length}
        value={preview.current}

        onIonChange={e => {
          let val = e.detail.value as { lower: number, upper: number };
          if (val.upper === props.data.length) {
            val.upper = 103;  // aby se tohle případně dobře hledalo
          }
          chart.current?.zoomScale("x", {min: val.lower, max: val.upper });
          range.current = val;
          preview.current = val;
          chart.current?.update();
        }}

        onIonInput={e => {
          let val = e.detail.value as { lower: number, upper: number };
          preview.current = val;
          chart.current?.update();
        }}
      />
    </IonItem>
  </IonList>
}