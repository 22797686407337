type _five<T extends any[]> = [...T, ...T, ...T, ...T, ...T]
type _ten<T extends any[]> = [..._five<T>, ..._five<T>]
type _reverse<s extends string> = s extends `${infer a}${infer b}` ? `${_reverse<b>}${a}` : s;

type _tuple<T, n extends string>
= n extends "" ? never
: n extends "0" ? []
: n extends "1" ? [T]
: n extends "2" ? [T, T]
: n extends "3" ? [T, T, T]
: n extends "4" ? [T, T, T, T]
: n extends "5" ? [T, T, T, T, T]
: n extends "6" ? [T, T, T, T, T, T]
: n extends "7" ? [T, T, T, T, T, T, T]
: n extends "8" ? [T, T, T, T, T, T, T, T]
: n extends "9" ? [T, T, T, T, T, T, T, T, T]
: n extends `${infer a}${infer b}` ? b extends "" ? never : [..._ten<_tuple<T, b>>, ..._tuple<T, a>]
: never

export type tuple<T, n extends number> = _tuple<T, _reverse<`${n}`>> & T[] & { "length" : n };

export function KeysOf<T extends object>(o : T) {
  return Object.keys(o) as (keyof T)[];
}