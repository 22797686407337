import { FC } from "react";
import { OverviewCard } from "../../components/OverviewCard";
import { useLoc } from "../../functions/Language";
import React, { IonCard, IonCardHeader, IonCardTitle, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonText } from "@ionic/react";
import { Page } from "../../components/Page";
import { useLoader } from "../../functions/networking/useLoader";
import { DvcPage } from "../../components/navigation/PageList";
import { DeviceCtx } from "../../components/navigation/ConditionalRoute";
import { RouterMap } from "../../components/navigation/RouterMap";
import { informationCircleOutline } from "ionicons/icons";
import { DeviceInfo, ResponseHead } from "../../functions/networking/NetTypes";

const route = RouterMap.unitInfo;

const Overview : FC<{
  data: ResponseHead
}> = (props) => {
  const loc = useLoc({en, cs});

  return <OverviewCard title={loc.title} href={route}>
    <Basic {...props} />
  </OverviewCard>
}

const Basic : FC<{
  data: ResponseHead
}> = (props) => {
  const loc = useLoc({en, cs});

  return <>
    <IonItem>
      <IonLabel>{loc.desc}</IonLabel>
      <IonText>{props.data.Device.Description}</IonText>
    </IonItem>
    <IonItem>
      <IonLabel>{loc.name}</IonLabel>
      <IonText>{props.data.Device.Name}</IonText>
    </IonItem>
    <IonItem>
      <IonLabel>{loc.connection}</IonLabel>
      <IonText>{props.data.Connection}</IonText>
    </IonItem>
    <IonItem>
      <IonLabel>{loc.fw}</IonLabel>
      <IonText>{props.data.Device.Firmware}</IonText>
    </IonItem>
    <IonItem>
      <IonLabel>{loc.hw}</IonLabel>
      <IonText>{props.data.Device.Version}</IonText>
    </IonItem>
  </>
}

const Detail : FC = () => {
  const loc = useLoc({en, cs});
  const loader = useLoader("Online", null);
  const loaders = [loader];

  return <Page title={loc.title} loaders={loaders}>
    <div style={{height: "calc(100% - 10px)", marginBottom: "5px", marginTop: "5px"}}>
      <DeviceCtx.Consumer>{dvcData => <IonRow style={{minHeight: "calc(100%)"}}>
        <IonCard style={{ minWidth: "25em", flex: "1" }}>
          <IonCardHeader>
            {loc.basic}
          </IonCardHeader>
          <IonList>
            {loader.data === null ? "" : <>
              <Basic data={loader.data.Head} />
            </>}
            <IonItem>
              <IonLabel>{loc.mqtt_id}</IonLabel>
              <IonText>{dvcData!.Mqtt_uid}</IonText>
            </IonItem>
          </IonList>
        </IonCard>

        <IonCard style={{ minWidth: "25em", flex: "1" }}>
          <IonCardHeader>
            {loc.equipment}
          </IonCardHeader>
          <IonList>
            {EquipmentIDs.map((v, i) => <IonItem key={i}>
              <IonLabel>{loc[v]}</IonLabel>
              <IonText>{EquipmentStatus(dvcData!.Equipment[v], loc.bStat)}</IonText>
            </IonItem>)}
          </IonList>
        </IonCard>
      </IonRow>}</DeviceCtx.Consumer>
    </div>
  </Page>
}

function EquipmentStatus(val : number | boolean, loc : [string, string]) {
  if (typeof val === "boolean") return loc[val ? 1 : 0];
  else return val;
}

const EquipmentIDs : (keyof DeviceInfo["Equipment"])[] = [
  "Analog_output",
  "Analog_outputs",
  "Analog_inputs_outputs",
  "Data_flash",
  "FRam",
  "Profibus",
  "RJ45",
  "RS485",
  "Relay_outputs",
  "Virtual_flash"
];

const en = {
  title: "Device information",

  basic: "Basic info",
  name: "Type",
  connection: "Connection",
  fw: "Firmware version",
  hw: "Hardware version",
  desc: "Description",
  mqtt_id: "MQTT id",

  equipment: "Equipment",
  Analog_inputs_outputs: "# of analog in/out",
  Analog_output: "Has analog out",
  Analog_outputs: "# of analog outs",
  Data_flash: "Data flash",
  FRam: "FRam",
  Profibus: "Profibus",
  RJ45: "RJ45",
  RS485: "RS485",
  Relay_outputs: "Relay ouputs",
  Virtual_flash: "Virtual flash",

  bStat: ["No", "Yes"] as [string, string]
}

const cs : typeof en = {
  title: "Informace o přístroji",

  basic: "Základní informace",
  name: "Typ",
  connection: "Zapojení",
  fw: "Verze firmware",
  hw: "Verze hardware",
  desc: "Popis",
  mqtt_id: "MQTT id",

  equipment: "Vybavení",
  Analog_inputs_outputs: "Počet analog in/out",
  Analog_output: "Má analog out",
  Analog_outputs: "Počet analog out",
  Data_flash: "Data flash",
  FRam: "FRam",
  Profibus: "Profibus",
  RJ45: "RJ45",
  RS485: "RS485",
  Relay_outputs: "Počet relé výstupů",
  Virtual_flash: "Virtuální flash",

  bStat: ["Ne", "Ano"]
}

export const UnitInfo = {
  Overview: Overview,
  path: route,
  Detail: Detail
}

export const PUnitInfo : DvcPage = {
  page: Detail,
  path: route,
  title: { en, cs },
  icon: informationCircleOutline
}