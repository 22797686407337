import { IonIcon, IonItem, IonLabel, IonList, RouterDirection } from "@ionic/react";
import { FC } from "react";
import { Glob, Language } from "../../functions/Language";
import { Energy } from "../../pages/Details/Energy";
import { PHarmonics } from "../../pages/Details/Harmonics";
import { PMeasurements } from "../../pages/Details/Measurements";
import { POnline } from "../../pages/Details/Online";
import { PUnitInfo } from "../../pages/Details/UnitInfo";
import { PVector } from "../../pages/Details/Vector";
import { POverview } from "../../pages/Overview";
import { DeviceCtx, DvcRoute } from "./ConditionalRoute";
import { RouterMap } from "./RouterMap";
import { IOState } from "../../pages/Details/IOState";
import { DeviceInfo } from "../../functions/networking/NetTypes";

export type DvcPage = {
  title : Glob<{ title : string }>,
  icon? : string,

  path : typeof RouterMap[keyof typeof RouterMap],
  page : FC,

  available? : (dvc : DeviceInfo) => boolean
}

export const DvcPageList : DvcPage[] = [
  POverview,
  //PMeasurements,  // tohle je stejně jen další menu
  POnline,
  PHarmonics,
  PVector,
  Energy,
  IOState,
  PUnitInfo,
];

const MeasurementPages : DvcPage[] = [
  POnline,
  PHarmonics,
  PVector,
  Energy,
  IOState
];

export const DvcPageRoutes = 
  [DvcPageList, [PMeasurements]].flat(1).map((v, i) =>
    <DvcRoute path={v.path} key={i}>
      <v.page />
    </DvcRoute>
  )


export const PageList : FC<{
  pages : DvcPage[],
  routerDirection : RouterDirection
}> = (props) => 
  <IonList>
    <DeviceCtx.Consumer>{dvc => dvc && 
      <Language.Consumer>{lng =>
        props.pages.filter(p => !p.available || p.available(dvc)).map((v, i) =>
        <IonItem key={i} routerLink={v.path} routerDirection={props.routerDirection}>
          {v.icon && <><IonIcon icon={v.icon} />&nbsp;</>}
          <IonLabel>{v.title[lng].title}</IonLabel>
        </IonItem>)
      }</Language.Consumer>
    }</DeviceCtx.Consumer>
  </IonList>

export const MeasurementPageList : FC = () => <PageList pages={MeasurementPages} routerDirection="forward" />
