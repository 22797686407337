import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { chevronDownOutline, chevronForwardOutline } from "ionicons/icons";
import React, { FC, PropsWithChildren, useMemo, useState } from "react";
import { DataCols, SelectFormatter } from "../pages/Details/Measurements";
import { FormatNumber } from "../functions/Formatting";
import { useLoc } from "../functions/Language";
import { fieldNames, units } from "../GeneralTranslations";
import { OnlineData } from "../functions/networking/NetTypes";

const colTitles = ["L1", "L2", "L3", "Σ"];
const llTitles = ["L1 - L2", "L2 - L3", "L3 - L1", ""];
type N3OnlineData = { [k in keyof OnlineData] : OnlineData[k] extends [number, number, number] ? k : never }[keyof OnlineData];

export const CollapsableTable : FC<{
  title : string,
  data : OnlineData,
  mam? : { min : OnlineData, avg : OnlineData, max : OnlineData }
  rows : (typeof CollapsableRow extends FC<infer T> ? T extends { row : infer U } ? U : never : never)[],
  LL? : boolean,
  labels : typeof fieldNames.cs
}> = (props) => {
  const loc = useLoc({en, cs});

  return <TableCollapser headerFields={props.LL ? llTitles : colTitles} title={props.title}>
    {props.rows.map((v, i) =>
      <CollapsableRow {...props} loc={loc} row={v} key={i} />
    )}
  </TableCollapser>
}

const en = {
  min: "min",
  avg: "average",
  max: "max"
}

const cs : typeof en = {
  min: "min",
  avg: "průměr",
  max: "max"
}

const DataRow : FC<{
  row : { d? : N3OnlineData, t? : N3OnlineData }
  data : OnlineData,
  label : string,
  class? : string,
  onClick? : typeof IonRow extends FC<{onClick : infer T}> ? T : never,
  collapser? : boolean,
  style? : any
}> = (props) => {
  const formatter = useMemo(() => SelectFormatter(props.row.d ?? "Current"), [props.row]);

  // TODO: pokud se něco změní, tohle se může pokazit
  if (props.row.d && isNaN(props.data[props.row.d][0])) return <></>
  return <IonRow onClick={props.onClick} className={props.class} style={props.style}>
    <IonCol size="3">{props.collapser === undefined ? <div style={{paddingLeft : "21px"}}>{props.label}</div> : <CollapseIndicator collapsed={props.collapser} title={props.label} color="secondary" /> }</IonCol>
    {props.row.d ? <DataCols formatter={formatter} data={props.data[props.row.d]} /> : <><IonCol /><IonCol /><IonCol /></>}
    <IonCol>{props.row.t ? formatter(props.data[props.row.t][0]) : ""}</IonCol>
  </IonRow>
}

const CollapseIndicator : FC<{
  collapsed : boolean,
  title : string,
  color? : string
}> = (props) => <div style={{display: "flex"}}>
  <IonIcon icon={props.collapsed ? chevronForwardOutline : chevronDownOutline } color={props.color} style={{minWidth: "1em"}} />
  <span style={{flex : 1}}>{props.title}</span>
</div>

const CollapsableRow : FC<{
  row : { d : N3OnlineData & keyof typeof fieldNames.cs, t? : N3OnlineData } | { t : keyof typeof fieldNames.cs, d? : undefined },
  data : OnlineData,
  mam? : { min : OnlineData, avg : OnlineData, max : OnlineData }
  loc : { min : string, avg : string, max : string },
  labels : typeof fieldNames.cs
}> = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const worthwhile = useMemo(() => {
    if (props.mam === undefined || (props.row.d === undefined && props.row.t === undefined))
      return false;
    
    return (props.row.d && !isNaN(props.mam.avg[props.row.d][0]))
      || (props.row.t && !isNaN(props.mam.avg[props.row.t][0]));
  }, [props.mam, props.row]);

  return <div onClick={() => setCollapsed(v => !v)} className="bordered">
    <DataRow
      row={props.row}
      label={`${props.labels[props.row.d || props.row.t]}${units[props.row.d || props.row.t] && ` [${units[props.row.d || props.row.t]}]`}`}
      data={props.data}
      collapser={worthwhile ? collapsed : undefined}
    />
    <span hidden={props.mam === undefined || collapsed}>
      <DataRow row={props.row} label={props.loc.max} data={props.mam?.max ?? props.data} style={{color : "var(--table-color-max)"}} />
      <DataRow row={props.row} label={props.loc.avg} data={props.mam?.avg ?? props.data} style={{color : "var(--table-color-avg)"}} />
      <DataRow row={props.row} label={props.loc.min} data={props.mam?.min ?? props.data} style={{color : "var(--table-color-min)"}} />
    </span>
  </div>
}

export const TableCollapser : FC<PropsWithChildren<{
  openState? : [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  title : string,
  headerFields : string[],
  margin? : string,
  sizes ?: string[],
}>> = (props) => {
  const [isOpen, setOpen] = props.openState ?? useState(true);

  return <div style={{padding: ".5%"}}>
    <IonGrid class="table">
      <IonRow class="header bheader" onClick={() => setOpen(v => !v)}>
        <IonCol size={props.sizes?.[0] ?? "3"}>
          <CollapseIndicator collapsed={!isOpen} title={props.title} />
        </IonCol>
        {props.headerFields.map((v, i) => <IonCol key={i} size={props.sizes?.[i + 1]}>{v}</IonCol>)}
      </IonRow>
      <div style={isOpen ? {} : {height: props.margin ?? "0px", visibility: "hidden", overflow: "hidden"}}>
        {props.children}
      </div>
    </IonGrid>
  </div>
}