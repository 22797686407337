import React, { FC } from "react";
import { useLoc } from "../functions/Language";
import { home, homeOutline } from "ionicons/icons";
import { UnitInfo } from "./Details/UnitInfo";
import { Measurements } from "./Details/Measurements";
import { Page } from "../components/Page";
import { NavButtonContent } from "../components/navigation/NavBar";
import { DvcPage } from "../components/navigation/PageList";
import { RouterMap } from "../components/navigation/RouterMap";
import { IonCardHeader, IonCardTitle, IonItem, IonRow, IonSpinner } from "@ionic/react";
import { SeqChart } from "../components/charts/SeqChart";
import { Energy } from "./Details/Energy";
import { SeqContext } from "../App";
import { DeviceCtx } from "../components/navigation/ConditionalRoute";

const route = RouterMap.overview;

const en = {
  title: "Overview",
  loading: "Loading...",
  chart: "Evolution of measurements",
}

const cs : typeof en = {
  title: "Přehled",
  loading: "Načítání...",
  chart: "Průběh hodnot",
}

const Button : FC = () => {
  const loc = useLoc({en, cs});
  return <NavButtonContent icon={home} text={loc.title} color="secondary" />;
}

const Component : FC = () => {
  const loc = useLoc({en, cs});

  return <Page title={loc.title} hideBackButton>
    <SeqContext.Consumer>{data => data.onlineData ? <>
      <IonRow>
        <span className="group" style={{marginBottom: "-3px"}}>
          <UnitInfo.Overview data={data.onlineData.Head} />
          <DeviceCtx.Consumer>{dvc => dvc?.Equipment.Data_flash &&
            <Energy.overview />
          }</DeviceCtx.Consumer>
        </span>
        <span className="group">
          <Measurements.Overview data={data.onlineData.Data} />
          <SeqChart chartId={0}>
            <IonCardHeader><IonCardTitle>
              {loc.chart}
            </IonCardTitle></IonCardHeader>
          </SeqChart>
        </span>
      </IonRow>
    </> : <IonItem lines="none">
      <IonSpinner />&nbsp;{loc.loading}
    </IonItem>}</SeqContext.Consumer>
  </Page>
}

export const Overview = {
  Button: Button,
  page: Component,
  path: route
}

export const POverview : DvcPage = {
  page: Component,
  path: route,
  title: {en, cs},
  icon: homeOutline
}