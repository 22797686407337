import { DeviceInfo, Fetchable, ResponseHead } from "./NetTypes";

export const FakeDevice : DeviceInfo = {
  Description: "Fake device",
  Firmware: "none",
  Version: "none",
  Name: "FakeDevice",
  Mqtt_uid: "mqtt_what",
  Equipment: {
    Analog_inputs_outputs: 1,
    Analog_output: true,
    Analog_outputs: 1,
    Data_flash: true,
    FRam: true,
    Profibus: true,
    Relay_outputs: 1,
    RJ45: true,
    RS485: true,
    Virtual_flash: true
  }
}

function GetFakeHarmonics(m = 1) : [number, number, number][] {
  return [...Array(40)].map((_, i) => [m / (i+1), m/(2 * i + 1), m/(i * i + 1)]);
}

function GetFakeHead(req : Fetchable) : ResponseHead {
  return {
    Connection: "3UN_3I",
    Date_time: 9e9,
    Online_interval: 5,
    File_state: "Actual",
    File_type: req,
    Device: {
      Description: "Fake device",
      Firmware: "none",
      Version: "none",
      Name: "FakeDevice",
    }
  }
}

const n3Keys = [
  "Angle_I", "Angle_U", "CosFi", "CosFi_3F",
  "Current", "Current_Zero", "Distortion_Power",
  "Frequency", "KFactor", "Over_Deviation_LL",
  "Over_Deviation_LN", "P", "P_3F", "Pf", "Pf_3F",
  "Q", "Q_3F", "Rotation", "S", "S_3F", "Tdd",
  "Temperature", "Thd_Current", "Thd_U_LL",
  "Thd_U_LN", "U_LL", "U_LN",
  "Unbalance_I_I0", "Unbalance_I_I2",
  "Unbalance_LN_U0", "Unbalance_LN_U2",
  "Under_Deviation_LL", "Under_Deviation_LN"
] as const;
const energyKeys = [
  "Energy_T1", "Energy_T2", "Energy_T3", "Energy_T4",
  "Phase_energy_L1", "Phase_energy_L2", "Phase_energy_L3"
] as const;

function GetFakeOnlineData() {
  let ret = {} as any;
  n3Keys.forEach(k => {
    ret[k] = [(Math.random() * 1000) >> 0, (Math.random() * 1000) >> 0, (Math.random() * 1000) >> 0]
  });
  energyKeys.forEach(k => {
    ret[k] = {
      Consumption: {
        Active_kWh: (Math.random() * 1000) >> 0,
        Inductive_kvarh: (Math.random() * 1000) >> 0,
        Capacity_kvarh: (Math.random() * 1000) >> 0
      },
      Distribution: {
        Active_kWh: (Math.random() * 1000) >> 0,
        Inductive_kvarh: (Math.random() * 1000) >> 0,
        Capacity_kvarh: (Math.random() * 1000) >> 0
      }
    }
  });
  return ret;
}

export function FetchFake(what: Fetchable) {
  if (what.startsWith("Online")) {
    return { Head: GetFakeHead(what), Data: GetFakeOnlineData() }
  } else if (what.startsWith("Harm")) {
    return { Head: GetFakeHead(what), Data: GetFakeHarmonics(what.endsWith("x") ? 1.1 : 1) }
  } else if (what === "Energy_Per_Day_Folder_Info") {
    return { Head: GetFakeHead(what), Data: { start: 15, end: 20 } }
  } else {
    throw "Not implemented";
  }
}