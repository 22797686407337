import { IonButton, IonItem } from "@ionic/react";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { DateInputItem } from "./DateInputItem";
import { useLoc } from "../functions/Language";
import { Resolution } from "../functions/networking/EnergyLoading";
import { DropdownSelectItem } from "./DropdownSelect";

function Resize(res : Resolution, start : Date, end : Date, fixEnd : boolean) {
  start = new Date(start);
  end = new Date(end);

  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  let diff = Math.floor((end.valueOf() - start.valueOf()) / (24 * 36e5));
  
  switch (res) {
    case Resolution.qh:
      if (diff < 0 || diff > 2) {
        if (fixEnd) {
          start.setTime(end.getTime());
          start.setDate(end.getDate() - 2);
        }
        else {
          end.setTime(start.getTime());
          end.setDate(start.getDate() + 2);
        }
      }
      break;
    case Resolution.hour:
      if (diff < 0 || diff > 7) {
        if (fixEnd) {
          let dow = end.getDay();
          start.setTime(end.getTime());
          start.setDate(start.getDate() - dow + 1);
          end.setDate(end.getDate() - dow + 7);
        } else {
          let dow = start.getDay();
          end.setTime(start.getTime());
          start.setDate(start.getDate() - dow + 1);
          end.setDate(end.getDate() - dow + 7);
        }
      }
      break;
    case Resolution.day:
      if (diff < 0) {
        if (fixEnd) {
          start.setTime(end.getTime());
          start.setDate(1);
        } else {
          end.setTime(start.getTime());
          end.setMonth(start.getMonth() + 1, 0);
        }
      } else if (diff > 92) {
        if (fixEnd) {
          start.setTime(end.getTime());
          start.setMonth(end.getMonth() - 2, 1);
        } else {
          end.setTime(start.getTime());
          end.setMonth(start.getMonth() + 3, 0);
        }
      }
      break;
    case Resolution.month:
      start.setDate(1);
      end.setMonth(end.getMonth() + 1, 0);
      diff = Math.floor((end.valueOf() - start.valueOf()) / (24 * 36e5));
      if (diff < 0 || diff > 366) {
        if (fixEnd)
          start.setFullYear(end.getFullYear() - 1, end.getMonth() + 1, 1);
        else
          end.setFullYear(start.getFullYear() + 1, start.getMonth(), 0);
      }
      break;
  }

  return { start, end };
}

export const TimeRangeInput : FC<{
  load : (start : Date, end : Date, res : Resolution) => void
}> = (props) => {
  const loc = useLoc({ en, cs });
  const [start, setStart] = useState(StartDate);
  const [end, setEnd] = useState(new Date);
  const [res, setRes] = useState(Resolution.month);
  const presentation = res === Resolution.month ? "month-year" : "date";
  const lastChangedWasUpper = useRef(true);

  function UpdateRange(start : Date, end : Date, res : Resolution) {
    const newRange = Resize(res, start, end, lastChangedWasUpper.current);
    setStart(newRange.start);
    setEnd(newRange.end);
    setRes(res);
  }

  return <>
    <DropdownSelectItem
      label={loc.resolution}
      options={loc.resValues}
      selected={res}
      setSelected={(r : Resolution) => UpdateRange(start, end, r)}
    />
    <DateInputItem
      label={loc.intStart}
      value={start}
      setValue={(s) => {
        lastChangedWasUpper.current = false;
        UpdateRange(s, end, res);
      }}
      presentation={presentation}
      locale={loc.locale}
    />
    <DateInputItem
      label={loc.intEnd}
      value={end}
      setValue={(e) => {
        lastChangedWasUpper.current = true;
        UpdateRange(start, e, res);
      }}
      presentation={presentation}
      locale={loc.locale}
    />
  
    <IonItem lines="none">
      <IonButton slot="start" onClick={() => {
          setRes(Resolution.month);
          setStart(StartDate);
          setEnd(new Date);
        }}
        style={{ height: "3em", "--border-radius": "6px" }}>{loc.reset}</IonButton>
      <IonButton slot="end" onClick={() => props.load(start, end, res)}
        style={{ height: "3em", "--border-radius": "6px" }}>{loc.load}</IonButton>
    </IonItem>
  </>
}

const en = {
  locale : "en-GB",

  resolution: "Resolution",
  resValues: ["Quarter hours", "Hours", "Days", "Months"],

  intStart: "Interval start",
  intEnd: "Interval end",

  reset: "Reset selection",
  load: "Load",
}

const cs : typeof en = {
  locale : "cs",

  resolution: "Rozlišení",
  resValues: ["Čtvrthodiny", "Hodiny", "Dny", "Měsíce"],

  intStart: "Začátek intervalu",
  intEnd: "Konec intervalu",

  reset: "Resetovat výběr",
  load: "Načíst",
}

function StartDate() {
  let date = new Date();
  date.setMonth(date.getMonth() - 6, 1);
  date.setHours(0, 0, 0, 0);
  return date;
}