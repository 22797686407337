import { FC, useReducer } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { IonCol, IonRow } from "@ionic/react";

export const energyUnits = ["kWh", "kvarh", "kvarh"];
export const powerUnits = ["kW", "kvar", "kvar"];

const LegendItem : FC<{
  chart : ChartJS<"bar">,
  index : number,
  v : { text : string, fillStyle? : any },
  power ?: boolean
}> = (props) => {
  const [, update] = useReducer((v : number) => v + 1, 0);

  return <p onClick={() => {
    props.chart?.setDatasetVisibility(props.index, !props.chart.isDatasetVisible(props.index));
    props.chart?.update();
    update();
  }} style={{cursor: "pointer"}}>
    <span style={{
      backgroundColor: props.chart?.isDatasetVisible(props.index) ? props.v.fillStyle?.toString() : undefined,
      borderColor: props.v.fillStyle?.toString(),
      borderWidth: "2px",
      borderStyle: "solid",
      display: "inline-block",
      width: "1em",
      height: "1em",
      marginRight: "5px",
    }} />
    {props.v.text} [{(props.power ? powerUnits : energyUnits)[props.index % 3]}]
  </p>
}

export const EnergyLegend : FC <{
  chart : ChartJSOrUndefined<"bar">,
  power ?: boolean
}> = (props) => {
  if (!props.chart?.options.plugins?.legend) return <></>;
  const items = props.chart.options.plugins.legend.labels?.generateLabels?.(props.chart);
  if (!items) return <></>;


  return <IonRow style={{width: "100%", fontSize: "small"}}>
    <IonCol>
      {[0, 1, 2].map(i => <LegendItem key={i} chart={props.chart!} index={i} v={items[i]} power={props.power} />)}
    </IonCol>
    <IonCol>
      {[3, 4, 5].map(i => <LegendItem key={i} chart={props.chart!} index={i} v={items[i]} power={props.power} />)}
    </IonCol>
  </IonRow>
}