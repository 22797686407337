import React, { FC, PropsWithChildren } from "react";
import { SeqContext, SequentialFields } from "../../App";
import { DropdownSelectItem } from "../DropdownSelect";
import { useLoc } from "../../functions/Language";
import { IonCard } from "@ionic/react";
import { usePersistentState } from "../../functions/usePersistentState";
import { LineChart } from "./LineChart";
import { fieldNames, phaseLabels, units } from "../../GeneralTranslations";
import { CosPhiChart } from "./CosPhiChart";

export const SeqChart : FC<PropsWithChildren<{
  chartId : number
}>> = (props) => {
  const loc = useLoc(fieldNames);
  const label = useLoc({en: "Quantity", cs: "Veličina"});
  const phaseNames = useLoc(phaseLabels);
  const [view, setView] = usePersistentState(`overviewChart${props.chartId}`, (val) => {
    let res = Number.parseInt(val ?? "");
    return isNaN(res) ? props.chartId : res;
  });

  return <IonCard>
    {props.children}
    <DropdownSelectItem
      label={label}
      selected={view} setSelected={setView} options={SequentialFields.map(f => loc[f])}
    />

    <SeqContext.Consumer>{data => 
      SequentialFields[view] === "CosFi_Mod"
      ? <CosPhiChart
          data={data.seqData["CosFi_Mod"]}
          phaseNames={phaseNames.LN}
          forceRedraw={data}
          generation={data.generation}
          times={data.times}
        />
      : <LineChart
          data={data.seqData[SequentialFields[view]]}
          phaseNames={SequentialFields[view] === "Frequency" ? singleStr : SequentialFields[view] === "U_LL" ? phaseNames.LL : phaseNames.LN}
          units={units[SequentialFields[view]]}
          forceRedraw={data}
          times={data.times}
          generation={data.generation}
        />
    }</SeqContext.Consumer>
  </IonCard>
}

const singleStr = [""];