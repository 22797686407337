import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { AppSettings } from "./AppSettings";
import { ReloadButton, useLoader } from "../functions/networking/useLoader"
import { NavBar } from "./navigation/NavBar";

export const Page : FC<PropsWithChildren<{
  title : string,
  hideBackButton? : boolean,
  additionalButtons? : ReactNode,
  loaders? : ReturnType<typeof useLoader>[],
  hideNav? : boolean,
  loggedOut ?: boolean
}>> = (props) => {
  return <IonPage>
    <IonHeader>
      <IonToolbar color="tertiary">
        {props.hideBackButton
        ? <></>
        : <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>}
        <IonTitle>{props.title}</IonTitle>
        <IonButtons slot="end">
          {props.additionalButtons ?? <></>}
          {props.loaders ? <ReloadButton loaders={props.loaders } /> : ""}
          <AppSettings loggedOut={props.loggedOut} />
        </IonButtons>
      </IonToolbar>
    </IonHeader>

    <IonContent>
      {props.children}
    </IonContent>

    {props.hideNav || <NavBar />}
  </IonPage>
}