import { MqttClient, connect } from "mqtt/dist/mqtt";
import { FakeDevice, FetchFake } from "./Notworking";
import { DeviceInfo, FetchMessage, FetchOptions, FetchResult, Fetchable, IsDeviceInfo, IsIOState, IsOnlineData, IsResponseHead, IsShortOnlineData, Message, MsgModes, ParsableResponses, ParseDayFiles, RType, SetOptions, SetResult, energy, energyKeys } from "./NetTypes";
import { KeysOf } from "../../FunnyTypes";
import { DEV } from "../../App";
import { SavePassword } from "capacitor-ios-autofill-save-password";
import { getPlatforms } from "@ionic/react";

const FAKE = false;
const DEBUG = false;

const topicRoot = "pla";
const requestTopic = "application_request";
const responseTopic = "application_data";
const MSG_TIMEOUT = 5e3;
const PRESENCE_PERIOD = 5e3;

let broker = "mqtts://mqtt.bmr.cz:5281/mqtt";
let brokerHost = "mqtt.bmr.cz";
const BrokerRE = /^mqtts?:\/\/(?<host>[^:/]+)(?::\d+)?.*/;
export function SetBroker(val : string) {
  broker = val;
  brokerHost = val.match(BrokerRE)?.groups?.host ?? "broker";
}

let client : MqttClient | null = null;
let dvc : string | null = null;
let userName : string | null = null;

export async function SetDvc(device : string | null) {
  if (device === null && DEV) {
    await login(localStorage.getItem("DEV_NAME"), localStorage.getItem("DEV_PWD"));
    device = localStorage.getItem("DEV_DEVICE");
  } else if (DEV) {
    localStorage.setItem("DEV_DEVICE", device!);
  }
  if (userName !== null && dvc !== null && client !== null)
    client.unsubscribe(`${userName}/${topicRoot}/${dvc}/${responseTopic}`);
  dvc = device;
  if (dvc !== null && client !== null)
    client.subscribe(`${userName}/${topicRoot}/${dvc}/${responseTopic}`);
}

export enum LoginFailureModes {
  connection,
  credentials,
}

export async function login(name: any, pwd: any) : Promise<{ succ : true } | { succ : false, mode : LoginFailureModes }> {
  if (FAKE) return { succ: true };
  if (DEV && (name === null || pwd === null)) return { succ: true };

  name = name.toString();
  pwd = pwd.toString();

  userName = name;

  if (DEV) {
    localStorage.setItem("DEV_NAME", name);
    localStorage.setItem("DEV_PWD", pwd);
  }
  const id = `mqtt_${Math.random().toString(16).slice(3)}`;

  client = connect(broker, {
    clientId: id,
    clean: true,
    connectTimeout: 4000,
    reconnectPeriod: 1000,
    username: `${name}@${brokerHost}`,
    password: pwd,
  });

  client.subscribe(`${userName}/${topicRoot}`);
  client.addListener("message", Recieve);
  client.addListener("message", DevicePresenceHandler);

  return await new Promise(res => {
    client!.once("connect", () => {
      client!.removeAllListeners("error");
      client!.addListener("error", console.warn);
      if (getPlatforms().includes('ios')) {
        SavePassword.promptDialog({
            username: name,
            password: pwd,
        });
      }
      res({ succ: true });
    });
    client!.once("error", e => {
      console.warn(e, "aborting login");
      client?.end();
      client = null;
      res({ succ: false, mode: LoginFailureModes.credentials });
    });
    (client as any).stream.once("error", (e: unknown) => {
      console.warn(e, "aborting login");
      client?.end();
      client = null;
      res({ succ: false, mode: LoginFailureModes.connection });
    });
    client!.once("disconnect", () => res({ succ: false, mode: LoginFailureModes.credentials }));
  });
}

export function disconnect() {
  if (client === null) return;
  client.end();
  client = null;
  userName = null;
  SetDvc(null);
}

let ResolveDevicePresence : (val: DeviceInfo | null) => void
    = (val) => console.error("Message recieved before handler was set", val);
function DevicePresenceHandler(topic: string, payload: Buffer) {
  if (topic !== `${userName}/${topicRoot}`)
    return;
  let o : any;
  try {
    o = JSON.parse(payload.toString());
    let dvcInfo = o.Device;
    if (o.File_type === "Presence" && IsDeviceInfo(dvcInfo)) {
      ResolveDevicePresence(dvcInfo);
      return;
    }
  } catch (e) {
    console.error(e);
  }
}

export let AskForPresenceImmediatelly = () => {};

let askingPromise : null | Promise<null> = null;
let askingTimeout : NodeJS.Timeout | number | null = null;
export function KeepAskingForPresence(
  callback : typeof ResolveDevicePresence
) {
  ResolveDevicePresence = callback;
  // Zajistí, že se ptáme jen v jedné smyčce
  if (askingPromise !== null) return askingPromise;

  askingPromise = new Promise<null>(StopAsking => {
    function ContinueAsking() {
      if (askingTimeout) clearTimeout(askingTimeout);
      if (userName === null || client === null || dvc !== null) return StopAsking(null);

      client.publish(`${userName}/${topicRoot}`, '{"Command": "Presence"}', {}, err => {
        if (err !== undefined) {
          console.error(err);
          return StopAsking(null);
        } else {
          if (client !== null && dvc === null) {
            new Promise<void>(res => {
              AskForPresenceImmediatelly = res;
              askingTimeout = setTimeout(res, PRESENCE_PERIOD);
            }).then(ContinueAsking);
          }
        }
      });
    }
    ContinueAsking();
  });
  askingPromise.finally(() => { askingPromise = null; });
  return askingPromise;
}


function HarmonnicsAsArray(data: any) : [number, number, number][] {
  if (typeof data !== "object") return [];
  let keys = Object.keys(data).sort();
  if (keys[0] !== "H_01" || keys[39] !== "H_40") return [];
  return keys.map(k => data[k]);
}

const sendQueue : Message[] = [];
const recieveQueue : FetchMessage<Fetchable>[] = [];

export type FetchListener<T extends Fetchable> = { request: T, handler: (val: RType<T>) => void }
const listeners : FetchListener<Fetchable>[] = [];
export function AddFetchListener(l : FetchListener<Fetchable>) {
  if (!listeners.includes(l)) listeners.push(l);
}
export function RemoveFetchListener(l : FetchListener<Fetchable>) {
  Remove(listeners, l);
}

const epochStart = new Date("Jan 01 2000 01:00:00 GMT+0100").valueOf();
export function ToDate(v : number) {
  return new Date(epochStart + v);
}

let senderRunning = false;
async function SendMessagesInQueue() {
  if (senderRunning) return;
  try {
    senderRunning = true;

    while (sendQueue.length) {
      let msg = sendQueue[0];
      let sent = await new Promise(res => {
        if (client === null) {
          res(false);
          return;
        }

        let req = `{"Command": "${msg.mode === MsgModes.Get ? "Get" : "Set"}_${msg.request}"${
          msg.options === null ? "" :
          ","+KeysOf(msg.options).map(k => `"${k}": ${msg.options![k]}`).join(",")
        }}`;
        try {
          if (DEBUG)
            console.debug("SEND", JSON.parse(req));
        } catch {
          console.warn(req);
        }
        client.publish(
          msg.topic,
          req,
          {},
          e => {
            if (e !== undefined) {
              console.error(e);
              res(false);
            } else {
              res(true);
            }
        });
      });
      if (sendQueue[0] === msg) {
        sendQueue.shift();
        if (!sent)
          msg.res({ err: true, kind: "send_fail" });
        else {
          if (msg.mode === MsgModes.Set) {
            msg.res({ err: false });
            return;
          }
            
          msg.topic = msg.topic.replace(/request$/, "data");
          recieveQueue.push(msg);
          setTimeout(() => {
            if (Remove(recieveQueue, msg)) {
              console.warn("timed out", msg.request, msg.options);
              msg.res({ err: true, kind: "timeout" });
            }
          }, MSG_TIMEOUT);
        }
      }
      //await new Promise(res => setTimeout(res, 300));
    }
  } finally {
    senderRunning = false;
  }
}

function Remove<T>(arr : T[], elem : T, hint? : number) {
  let i : number;
  if (hint && arr[hint] === elem) {
    i = hint;
  } else {
    i = arr.indexOf(elem);
    if (i < 0) return false;
  }
  if (arr.length === i + 1) arr.pop();
  else arr[i] = arr.pop()!;
  return true;
}

function Send(msg : Message) {
  sendQueue.push(msg);
  if (!senderRunning)
    SendMessagesInQueue();
}

function DistributeResult<T extends ParsableResponses, U extends Fetchable & T>(topic : string, request : T, data : FetchResult<U>) {
  let i = 0;
  while (i < recieveQueue.length) {
    let msg = recieveQueue[i];
    if (msg.topic === topic && request === msg.request) {
      msg.res(data);
      Remove(recieveQueue, msg, i);
    }
    else i++;
  }
  if (!data.err)
    for (let l of listeners) {
      if (`${userName}/${topicRoot}/${dvc}/${responseTopic}` === topic && l.request === request) {
        l.handler(data.val);
      }
    }
}

function StartsWith<T extends string>(s : string, t : T) : s is `${T}${any}` {
  return s.startsWith(t);
}

function Recieve(topic : string, payload : Buffer) {
  try {
    let o = JSON.parse(payload.toString());
    if (typeof o !== "object") {
      console.warn("failed to parse response", payload.toString());
      return;
    }
    if (DEBUG) console.debug("RECV", o?.Head?.File_type, o);
    let head = o.Head;
    if (!IsResponseHead(head)) return;
    let data = o.Data;
    if (StartsWith(head.File_type, "Online")) {
      if (head.File_type === "Online_Short") {
        if (!IsShortOnlineData(data)) DistributeResult(topic, head.File_type,
          { err: true, kind: "parse_fail" });
        else DistributeResult(topic, head.File_type,
          { err: false, val: { Head: head, Data: data } });
      } else {
        if (head.File_type !== "Online") {
          const nn3 = [NaN, NaN, NaN]
          data["Rotation"] = nn3;
          if (head.File_type === "Online_Min") {
            data["Over_Deviation_LL"] = nn3;
            data["Over_Deviation_LN"] = nn3;
          } else if (head.File_type === "Online_Max") {
            data["Under_Deviation_LL"] = nn3;
            data["Under_Deviation_LN"] = nn3;
          }
          const nenergy : energy = {
            Consumption: {
              Active_kWh: NaN,
              Capacity_kvarh: NaN,
              Inductive_kvarh: NaN
            },
            Distribution: {
              Active_kWh: NaN,
              Capacity_kvarh: NaN,
              Inductive_kvarh: NaN
            }
          }
          energyKeys.forEach(k => data[k] = nenergy);
        }

        if (!IsOnlineData(data)) DistributeResult(topic, head.File_type,
          { err: true, kind: "parse_fail" });
        else DistributeResult(topic, head.File_type,
          { err: false, val: { Head: head, Data: data } });
      }
    }
    else if (StartsWith(head.File_type, "Harm")) {
      DistributeResult(topic, head.File_type,
        { err: false, val: { Head: head, Data: HarmonnicsAsArray(data) } });
    }
    else if (head.File_type === "Energy_Per_Day_Folder_Info" || head.File_type === "Energy_Folder_Info") {
      DistributeResult(topic, head.File_type,
        { err: false, val: { Head: head, Data: data } });
    }
    else if (head.File_type === "Energy_Per_Day_File" || head.File_type === "Energy_File") {
      let res = ParseDayFiles(data);
      if (!res.success) {
        console.error("Failed to parse day file", data);
        return;
      }
      
      for (let i = 0; i < recieveQueue.length; i++) {
        let msg = recieveQueue[i];
        if ((msg.request !== head.File_type && msg.request !== "Energy_File")
        || msg.topic !== topic
        || res.value[(msg as FetchMessage<"Energy_Per_Day_File"> | FetchMessage<"Energy_File">).options.File_ID] === undefined
        ) continue;

        msg.res({ err: false, val: { Head: head, Data: res.value }});
        Remove(recieveQueue, msg);
        i--;
      }
    }
    else if (head.File_type === "IO_State") {
      if (!IsIOState(data)) DistributeResult(topic, head.File_type,
        { err: true, kind: "parse_fail" });
      else DistributeResult(topic, head.File_type,
        { err: false, val: { Head: head, Data: data } });
    }
    else {
      let _ : never = head.File_type;
      throw `Unknown File_type: '${head.File_type}'`
    }
  } catch (e) {
    console.log(payload.toString());
    console.error(e);
  }
}

export async function Fetch<T extends Fetchable>(what : T, options : FetchOptions<T>) : Promise<FetchResult<T>> {
  if (dvc === null || userName === null) {
    if (DEV) {
      await login(localStorage.getItem("DEV_NAME"), localStorage.getItem("DEV_PWD"));
      dvc = localStorage.getItem("DEV_DEVICE");
    } else {
      return { err : true, msg: "Device not selected", kind: "send_fail" };
    }
  }
  if (FAKE) {
    return new Promise(res => setTimeout(() => res(
      { err: false, val : FetchFake(what) as RType<T> }
    ), 100));
  }
  return new Promise(res => Send({
    topic: `${userName}/${topicRoot}/${dvc}/${requestTopic}`,
    request: what,
    res,
    options,
    mode: MsgModes.Get
  }));
}

export async function SetUnitConfig<K extends keyof SetOptions>(what : K, options : SetOptions[K]) : Promise<SetResult> {
  if (dvc === null || userName === null) {
    if (DEV) {
      await login(localStorage.getItem("DEV_NAME"), localStorage.getItem("DEV_PWD"));
      dvc = localStorage.getItem("DEV_DEVICE");
    } else {
      return { err : true, msg: "Device not selected", kind: "send_fail" };
    }
  }
  if (FAKE) {
    return new Promise(res => setTimeout(() => res({ err: false }), 100));
  }
  return new Promise(res => Send({
    topic: `${userName}/${topicRoot}/${dvc}/${requestTopic}`,
    request: what,
    res,
    options,
    mode: MsgModes.Set
  }));
}